.account {
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow-y: auto;
  // nbackground: url(#{$assetPath}/60959ac9-b5c1-4c01-8f85-68c46dda2e5d.svg) bottom;
  background-repeat: repeat-x;
  background-size: contain;
  background-color: $color-light-gray;

  /*@include themify($themes) {
    background: themed('colorBackgroundBody');
  }*/
  .form__form-group-icon {
    font-size: 18px;
    padding: 2px 6px;
    color: $color-gray;
  }

  .form-control {
    border-radius: 0 0.25rem 0.25rem 0;
  }

  &.account--photo {
    background: url(../../shared/img/404/bg_404.png) no-repeat center;
    background-size: cover;

    .account__card {
      background-color: rgba(0, 10, 16, 0.8);
      color: #ffffff;
    }

    .form__form-group-icon,
    .form__form-group-button {
      background: transparent;
    }

    .form__form-group-icon,
    input:not(:last-child) {
      border-right: none;
    }

    .form__form-group-button,
    input {
      border-left: none;
    }

    .form__form-group-button.active {
      border-color: #eff1f5;

      svg {
        fill: $color-accent;
      }
    }

    input {
      color: #ffffff;

      &:focus {
        border-color: #eff1f5;
      }
    }

    p {
      color: #ffffff;
    }

  }


  .copyright {
    font-size: 90%;
    color: $color-gray;
    margin-top: 40px;
    margin: 20px $siteMobileMargin;
    text-align: center;
  }
}

.account__wrapper {
  margin: auto;
  padding: 30px 10px;
}

.account__card {
  background-color: #ffffff;
  padding: 40px 60px;
  max-width: 520px;
  width: 100%;
  box-shadow: -8px 8px 0px $color-black;
  border-radius: 50px;
  animation: flipV 1s;
}

@keyframes flipV {
  0% {
    transform: scale(0);
    //opacity: 0;
  }

  to {
    transform: scale(1);
    -webkit-animation-timing-function: ease-in;
    //opacity: 1;
  }
}

.account__profile {
  text-align: center;
}

.account__btns {
  display: flex;
  width: 100%;
  justify-content: center;

  .btn {
    margin-top: 10px;
    white-space: nowrap;

    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
}

.account__links {
  width: 100%;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 90%;
  text-align: right;
}

.account__btn {
  width: 48%;
  margin-right: 0;
}

.btn-login {
  margin-right: 4%;
}

.register__btns {
  .account__btn {
    margin: auto;
  }
}

.account__avatar {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  overflow: hidden;
}

.account__name {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 15px;
  margin-top: 5px;
}

.account__sub {
  margin-top: 0;
  margin-bottom: 10px;
  color: $color-additional;
  font-size: 11px;
  line-height: 15px;
}

.account__more {
  text-align: center;

  &:before {
    clear: both;
  }

  a {
    color: $color-blue;
    transition: all 0.3s;

    &:hover {
      color: $color-blue-hover;
      text-decoration: none;
    }
  }
}

.account__forgot-password {
  position: absolute;
  font-size: 11px;
  line-height: 15px;
  bottom: -18px;
  right: 0;

  a {
    color: $color-blue;

    &:hover {
      color: $color-blue-hover;
      text-decoration: none;
    }
  }
}

.form__form-group--forgot {
  margin-bottom: 40px;
}

.account__or {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;

  &:before,
  &:after {
    content: "";
    height: 1px;
    width: calc(50% - 90px);
    background: #dddddd;
    position: absolute;
    top: 10px;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
}

.account__social {
  text-align: center;

  .icon-google {
    vertical-align: top;
    margin-right: 5px;
  }

  .btn {
    margin-right: 0;
  }
}

.account__social-btn {
  display: inline-block;
  height: 38px;
  width: 38px;
  border-radius: 5px;
  padding: 9px;
  margin-right: 10px;
  transition: all 0.3s;
  border: none;

  &:last-child {
    margin-right: 0;
  }

  svg {
    fill: #ffffff;
    height: 20px;
    width: 20px;
  }

  &.account__social-btn--facebook {
    background: #4766a4;

    &:hover {
      background: darken(#4766a4, 10%);
    }
  }

  &.account__social-btn--google {
    background: #c74d4d;

    &:hover {
      background: darken(#c74d4d, 10%);
    }
  }
}


.account__logo {
  background: url(#{$assetPath}/quobal-logo-only.svg) no-repeat center top;
  background-size: contain;
  height: 100px;
}


.account__head {
  margin-bottom: 30px;
  text-align: center;
}


.account__title {
  color: $color-black;
  font-size: 18px;
}


@media screen and (max-width: 520px) {
  .account {
    background-color: $color-white;
  }

  .account__card {
    padding: 35px 30px;
    box-shadow: 0 0 0 transparent;
    background: transparent;
  }
}

@media screen and (max-width: 425px) {
  .account__btns {
    flex-wrap: wrap;
  }
}


.seller__redeem {

  .quobal-logo {
    width: 260px;
    height: 150px;
    text-align: center;
    background: url(#{$assetPath}/quobal-logo-only.svg) no-repeat center;
    text-indent: 100%;
    overflow: hidden;
    margin: $elementMargin auto;
    z-index: 1;
  }

  .subhead,
  p {
    text-align: center;
    margin-bottom: $elementMargin;
  }

  .subhead {
    text-shadow:
      3px 3px 0 #000,
      -2px -1px 0 #000,
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000;
    -webkit-text-fill-color: $color-white;
    font-weight: $font-xbold;
    font-size: 26px;
  }

  p {
    font-size: 16px;
  }

  .row {
    justify-content: center;
  }

  .redeem__code__container {
    .form__form-group {
      text-align: center;
    }

    .form__form-group-field {
      width: 250px;
    }
  }

  .quo__body-inviting{
    &.quo__body{
      background-color: $color-white;
    }
    .quo__info{
      display: block;
      text-align: center;
    }
    .quo__badge{
      margin-left: auto;
      margin-right: auto;
    }
  }
}