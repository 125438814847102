.gallery {

  img {
    width: 100%;
    height: auto;
  }

  .no-photo{
    width: 100%;
    padding-top: 0;
    padding-bottom: 30px;
    .icon{
      width: 120px;
      height: 120px;
    }
  }
}

.gallery__top {
  text-align: center;

  .btn{
    margin-top: 20px;
  }

  .page-title{
    display: inline-block;
    margin-right: 10px;
  }
  .dropdown-menu{
    background-color: $color-black;
    
    .dropdown-item{
      padding-top: 8px;
      padding-bottom: 8px;
    }
    .dropdown-item, a{
      color: $color-white;
      font-size: 14px;

      &:hover{
        color: $color-accent;
        background-color: inherit;
      }
    }
  }
}

.gallery__list{
  position: relative;
  width: 100%;
  max-width: 100%;

  .gallery__img-left,
  .gallery__img-right{
    position: absolute;
    top: calc(50% - 15px);
    background-color: $color-black;
    color: $color-white;

    &:hover,
    &:active {
      border-color: $color-accent;
      background-color: $color-accent;
      color: $color-black;
    }
  }

  .gallery__img-left{
    left: 10px;
  }

  .gallery__img-right{
    right: 10px;
  }
}


.gallery__img-container{
  width: 100%;
  min-height: 120px;
  margin: 0 auto;
  vertical-align: middle;
  display: flex;
  flex-wrap: wrap;
}

.gallery__img-wrap {
  width: 20%;
  max-height: 160px;
  overflow: hidden;
  border: none;
  padding: 0;
  display: inline-block;
  background-color: $color-white;

  @media screen and (max-width: 768px) {
    height: 120px;
  }
}


.submit-container {
  position: relative;
  text-align: center;
}

/* Bottom left text */
// .bottom-left {
//   position: absolute;
//   bottom: 8px;
//   left: 16px;
// }

.click-for-upload-profile {
  padding: 5px;
  cursor: pointer;
  font-size: 20px;
  margin-bottom: 0;

  &:hover,
  &:active {
    background: transparent;
    color: $color-blue;
  }
}