.topbar {
  position: absolute;
  display: flex;
  width: 100%;
  top: 0;
  padding-left: $siteMargin;
  padding-right: $siteMargin;
  -webkit-transition: background-color 0.5s ease-out;
  -moz-transition: background-color 0.5s ease-out;
  -o-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out;
  z-index: 101;

  @include themify($themes) {
    &.scroll {

      .MuiInput-underline:before {
        border-color: $color-black;
      }
    }
  }

  @media screen and (max-width: 1004px) {
    padding-left: $siteMobileMargin;
    padding-right: $siteMobileMargin;
  }

  .container {
    position: relative;
  }

  .topbar__discover {
    display: flex;
    align-items: center;
    font-weight: $font-xbold;
  }

  .topbar__button--desktop {
    display: none;
  }

  .quobal__logo {
    display: none;
  }

  &.topbar__info {
    min-height: 40px;
    position: fixed;
    max-width: 1630px;

    .topbar__right {
      align-items: center;
    }

    .topbar__button {
      display: block;
      position: relative;
      right: 0;
    }

    .topbar__get-start-button {
      margin-bottom: 0;
    }

    .quobal__logo__container {
      position: fixed;
      bottom: 15px;
      left: 15px;
      z-index: 10;
      height: 50px;
      font-weight: $font-bold;
  
      a {
        display: inline-block;
        color: $color-black;
      }
    }
  }

  .topbar__login {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
}

.topbar__button {
  width: 60px;
  height: 60px;
  display: flex;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  position: fixed;
  z-index: 10;

  &:focus {
    outline: none;
  }

  &:hover {
    color: $color-blue;
  }

}

.mobile__search_btn,
.topbar__btn--mail,
.topbar__gallery,
.topbar__balance,
.topbar__quo,
.topbar__search,
.mobile__search {
  display: flex;
  align-items: center;
  color: $color-text;

  .topbar__btn {
    color: $color-text;
    align-items: center;
  }
}

.topbar__search {
  margin-bottom: $elementXMargin;

  .topbar__search-field {
    background-color: $color-white;
    -webkit-transition: background-color 0.5s ease-out;
    -moz-transition: background-color 0.5s ease-out;
    -o-transition: background-color 0.5s ease-out;
    transition: background-color 0.5s ease-out;
  }

  .MuiAutocomplete-root {
    margin-right: 10px;
  }

  .MuiAutocomplete-input {
    min-width: 50px !important;
    transition: 0.3s;

    &:hover,
    &:focus,
    &:active {
      width: 200px;
    }
  }

  &:hover,
  &:focus,
  &:active {
    .topbar__search-field {
      background-color: $color-blue;

      -webkit-transition: background-color 0.5s ease-out;
      -moz-transition: background-color 0.5s ease-out;
      -o-transition: background-color 0.5s ease-out;
      transition: background-color 0.5s ease-out;

      input {
        color: $color-accent;
      }
    }
  }
}

.topbar__search-result {
  display: block;
  padding: 4px;
}


.MuiAutocomplete-popper {

  .topbar__avatar-img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}

.MuiAutocomplete-groupLabel {
  background: transparent;
}

.MuiPaper-root {
  border: 4px solid $color-black;
  border-radius: 15px !important;
  box-shadow: none;
}


.topbar__right-search {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.topbar__search-field {

  &.MuiFormControl-root {
    padding-left: 10px;
    padding-right: 10px;
    min-width: 100px;
    border-radius: 50px;
    transition: border 0.3s;
    //border: 1px solid $color-white;
    color: $color-black;
  }

  &::placeholder {
    color: #cac1c1;
    padding-right: 50px;
  }

  .MuiIconButton-root {
    font-size: 1em;
  }

  .MuiAutocomplete-popupIndicator {
    display: none;
  }

  .MuiInput-underline:after,
  .MuiInput-underline:before {
    display: none;
  }

  .MuiSvgIcon-root {
    font-size: 1em;
  }
}

.MuiIputBase-root {
  font-family: 'Spartan', sans-serif !important;
}

.MuiAutocomplete-paper {
  font-family: 'Spartan', sans-serif !important;

  .MuiAutocomplete-option {
    display: block !important;
    font-size: 16px;

    a {
      color: $color-black;
    }
  }
}

.MuiSvgIcon-Primary {
  color: $color-black;
}

.MuiInput-underline {
  content: none;
}


.topbar__button-icon {
  margin: auto;
  transition: all 0.3s;
  width: 16px;
  z-index: 101;
}

.topbar__right {
  position: absolute;
  display: flex;
  height: 100%;
  right: 0;
}

.topbar__right-over {
  display: flex;
  flex: auto;
  justify-content: space-between;
}

.topbar__left {
  position: absolute;
  display: flex;
  height: 100%;
  left: 0px;
}

.topbar__greeting {
  display: flex;
  align-items: center;
  font-weight: $font-xbold;
  margin-right: 7px;
}

.topbar__avatar {
  height: 100%;
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 0;
  border: none;
  transition: all 0.3s;
  box-shadow: none;
  padding: 0 7px;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  &:before {
    display: none;
  }
}

.topbar__avatar-img,
.topbar__avatar-name,
.topbar__icon {
  margin: auto 0;
}

.topbar__avatar-img {
  border-radius: 50%;
  height: 36px;
  width: 36px;
}

.topbar__avatar-name {
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  display: none;
  margin-left: 10px;
}

.topbar__icon {
  height: 18px;
  margin-top: auto;
  fill: $color-black;
  margin-left: 0;
}

.topbar__menu {
  width: 200px;
  border-radius: 15px;
  border: 4px solid $color-black;
  padding: 15px 0;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  margin-top: 0;

  @include themify($themes) {
    background: themed('colorBackground');
  }

  button {
    padding: 0;

    &:hover {

      @include themify($themes) {
        background-color: themed('colorHover');
      }
    }
  }

  *:focus {
    outline: none;
  }
}

.topbar__info_menu {
  height: 100%;
  background-color: $color-black;

  .topbar__button {
    font-size: 32px;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    color: $color-accent;
  }

  .topbar__link {
    height: auto;
    text-align: center;
    color: $color-accent;

    &:hover {
      color: $color-blue;
    }
  }

  .topbar__link-title {
    font-size: 18px;
    line-height: 1.5;
    font-weight: $font-xbold;
  }

  .topbar__link-language {
    margin-bottom: 30px;
    background-color: transparent;
    border: 0;

    .topbar__icon {
      fill: $color-accent;
    }

    &:hover {
      .topbar__icon {
        fill: $color-blue;
      }
    }
  }

  .btn-login {
    border-color: $color-white;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
    display: block;
  }

}

.topbar__info_menu-wrap {
  z-index: 101;
  position: absolute;
  width: 100%;
  height: 100vh;
  min-width: 280px;
  right: 0px;
  top: 0;

  @media screen and (max-width: 1004px) {
    left: auto;
    right: 0;
  }
}

.topbar__menu-wrap {
  z-index: 101;
  position: absolute;
  width: 100%;
  min-width: 230px;
  left: 0px;

  @media screen and (max-width: 1004px) {
    left: auto;
    right: 0;
  }
}

.topbar__link {
  display: flex;
  padding: 9px 20px;
  transition: all 0.3s;
  height: 32px;
  width: 100%;
  position: relative;
  cursor: pointer;
  color: $color-black;

  /*&:before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 2px;
    background: $color-blue;
    opacity: 0;
    transition: all 0.3s;
    left: 0px;
  }*/

  &:hover {
    background-color: $color-accent;

    &:before {
      opacity: 1;
    }
  }
}

.topbar__link-title {
  display: flex;
  margin: 0;
  font-size: 14px;
  line-height: 16px;
}

.topbar__link-icon {
  font-size: 18px;
  line-height: 13px;
  margin-right: 10px;
}

.topbar__menu-divider {
  margin: 15px 0;

  @include themify($themes) {
    border-top: 1px solid themed('colorBorder');
  }
}

.topbar__profile {
  position: relative;
  margin-bottom: 0;
  // display: flex;
  // align-items: center;
  margin-left: 0px;
}

.topbar__collapse {
  position: relative;
  display: block;

  &.topbar__collapse--language {
    min-width: 70px;
    display: block;

    &>button {
      // padding: 0 4px;
      width: 100%;
    }
  }

  @media screen and (min-width: 568px) {
    display: block;
  }
}

.topbar__collapse-content {
  width: 270px;
  position: absolute;
  box-shadow: 0 10px 25px 0 rgba(33, 36, 50, 0.13);
  z-index: 102;
  left: 0px;
  top: 120%;
  background-color: $color-light-gray;

  @media screen and (max-width: 1024px) {
    right: -150px;
  }

  @media screen and (min-width: 520px) {
    width: 330px;
  }

  &.topbar__collapse-content--language {
    // max-width: 75px;
    width: 100%;

    @media screen and (max-width: 1024px) {
      left: 0%;
    }
  }
}

.topbar__language-btn {
  width: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 15px;
  line-height: 22px;
  transition: 0.3s;
  padding: 6px 8px 6px 12px;

  &:first-child {
    padding-top: 12px;
  }

  &:last-child {
    padding-bottom: 12px;
  }

  text-align: left;

  &:hover {
    background-color: $color-accent;
  }
}

.topbar__language-btn-title {
  display: flex;
  align-items: center;
  margin: auto 0;

  &:not(:last-child) {
    margin-right: 5px;
  }

  img {
    height: 11px;
    width: 16px;
    margin-right: 4px;
  }
}

.topbar__back {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: transparent;
  border: none;
}

.topbar__collapse-title-wrap {
  padding: 20px 15px 15px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
  }
}

.topbar__collapse-item {
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  position: relative;
  height: 62px;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 70px;
  padding-right: 55px;

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
  }
}

.topbar__collapse-img-wrap {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  left: 15px;
}

.topbar__collapse-message {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  color: $color-gray;
  text-align: left;

  &.topbar__collapse-message--mail {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.topbar__collapse-name {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  color: $color-accent;
}

.topbar__collapse-date {
  position: absolute;
  top: 12px;
  font-size: 10px;
  color: $color-gray;
  margin-top: 2px;
  right: 15px;
}

.topbar__collapse-link {
  display: block;
  padding: 10px;
  text-transform: uppercase;
  color: $color-accent;
  transition: 0.3s;
  text-align: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;

  &:hover {
    color: $color-accent-hover;
  }
}

.topbar__collapse-title {
  font-size: 14px;
  line-height: 16px;
}

.topbar__collapse-button {
  color: lighten($color-gray, 25%);
  border: none;
  padding: 0;
  font-size: 12px;
  line-height: 16px;
  transition: 0.3s;
  background: transparent;

  &:hover {
    color: $color-accent;
  }

  text-align: right;
}

.topbar__btn--new {
  .topbar__btn-new-label {
    position: absolute;
    top: 20px;
    left: 14px;

    &>div {
      position: relative;

      &:before {
        background-color: rgba(224, 83, 111, 0.2);
        content: "";
        position: absolute;
        top: 50%;
        border-radius: 50%;
        animation: beforePulse 1.5s infinite;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:after {
        height: 7px;
        width: 7px;
        background-color: $color-red;
        content: "";
        position: absolute;
        top: 50%;
        border-radius: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.topbar__btn {
  font-size: 15px;
  height: 100%;
  padding: 0 10px;
  cursor: pointer;
  position: relative;
  display: flex;
  border: none;
  background: transparent;
  transition: all 0.3s;
  color: $color-black;
  white-space: nowrap;

  &.mobile__search_btn {
    display: none;
  }

  &:hover {

    color: $color-blue;

    .icon-quo,
    .la-paper-plane,
    .la-wallet {
      margin-top: -8px;
      transition: all 0.3s;
    }

  }

  svg {
    margin: auto;
    height: 18px;
    width: 18px;
    fill: $color-black;
  }

  &.topbar__btn--new {

    .topbar__btn-new-label {
      position: absolute;
      top: 20px;
      left: 14px;

      &>div {
        position: relative;

        &:before {
          background-color: rgba(224, 83, 111, 0.2);
          content: "";
          position: absolute;
          top: 50%;
          border-radius: 50%;
          animation: beforePulse 1.5s infinite;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:after {
          height: 7px;
          width: 7px;
          background-color: $color-red;
          content: "";
          position: absolute;
          top: 50%;
          border-radius: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  @keyframes beforePulse {

    from {
      width: 7px;
      height: 7px;
    }

    25% {
      width: 13px;
      height: 13px;
    }

    to {
      width: 7px;
      height: 7px;
    }
  }
}

.topbar__nav {
  width: 75%;
  display: none;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-right: 140px;
}

.topbar .topbar__nav-dropdown-toggle {
  height: 60px;
  background: transparent;
  border-radius: 0;
  border: none;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  @include themify($themes) {
    color: themed('colorText');
  }

  &:before {
    display: none;
  }

  &:hover,
  &:focus,
  &:active,
  &:focus:active {

    @include themify($themes) {
      background-color: themed('colorHover');
    }
  }

  svg {
    height: 16px;
    width: 16px;
    fill: $color-additional;
    margin-left: 3px;
    margin-right: 0px;
  }
}

.topbar__nav-dropdown-menu {
  width: 240px;
  border-top: 2px solid $color-accent;

  button {
    padding: 0;
  }
}

.topbar__link-badge {
  position: relative;
  width: 26px;
  height: 14px;
  background-color: $color-red;
  font-size: 8px;
  font-weight: 400;
  padding: 2px;
  line-height: 9px;
  text-transform: uppercase;
  border-radius: 7px;
  margin-left: 5px;

  span {
    position: absolute;
    top: 3px;
    width: 26px;
    text-align: center;
    left: 0px;
  }
}

.topbar__nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 10px 25px;
  transition: 0.3s;
  font-size: 14px;

  &:hover {

    @include themify($themes) {
      background-color: themed('colorHover');
    }
  }

  @include themify($themes) {
    color: themed('colorText');
  }
}

.topbar__category-wrap {
  position: relative;

  &:hover {

    .topbar__submenu {
      opacity: 1;
      width: auto;
      height: auto;
    }
  }
}

.topbar__category-icon {
  position: absolute;
  font-size: 10px;
  line-height: 14px;
  right: 20px;

  @include themify($themes) {
    color: themed('colorIcon');
  }
}

.rtl-support {

  .topbar__submenu {
    transform: none;
    right: 100%;
  }

  .topbar__category-icon {
    transform: rotate(180deg);
  }
}

.ltr-support {

  .topbar__submenu {
    right: 1px;
  }
}

.topbar__submenu {
  position: absolute;
  top: 0;
  transform: translateX(100%);
  transition: 0.3s;
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;

  @include themify($themes) {
    background-color: themed('colorHover');
  }

  .topbar__link {

    &:hover {

      @include themify($themes) {
        background-color: themed('colorBackground');
      }
    }
  }
}


.layout--top-navigation {

  .topbar__search-field {

    &::placeholder {
      color: lighten($color-gray, 25%);
    }

    &.topbar__search-field--open {
      width: 55vw;

      @media screen and (max-width: 1200px) {
        width: 59vw;
      }

      @media screen and (max-width: 1049px) {
        width: 20vw;
      }
    }
  }
}



@media screen and (min-width: 480px) {

  .topbar__avatar-name {
    display: block;
  }

  .topbar__menu {
    width: 100%;
    left: 0px !important;
  }
}

@media screen and (min-width: 992px) {

  .topbar__button {

    &.topbar__button--mobile {
      display: none;
    }
  }

  .topbar.topbar--navigation {

    .topbar__button.topbar__button--mobile {
      display: none;
    }
  }

  .topbar__btn {
    font-size: 11px;
  }
}

@media screen and (min-width: 1400px) {

  .topbar__nav {
    display: flex;
  }

  .topbar.topbar--navigation {

    .topbar__logo {
      margin-left: 15px;
    }

    .topbar__button.topbar__button--desktop {
      display: none;
    }

    .topbar__button.topbar__button--mobile {
      display: none;
    }

    .topbar__avatar-name {
      display: none;
    }

    .topbar__profile {
      margin-left: 0px;
    }
  }
}

@media screen and (min-width: 1200px) {

  .topbar.topbar--navigation {

    .topbar__avatar-name {
      display: block;
    }
  }
}

@media screen and (min-width: 1920px) {

  .topbar__nav-dropdown-toggle,
  .topbar__nav-link {
    width: 240px;
  }
}

@media screen and (max-width: 1550px) {

  .topbar__nav {
    width: 65%;
  }
}

@media screen and (max-width: 992px) {

  .topbar {
    position: absolute;

    .quobal__logo__container {
      position: fixed;
      bottom: 15px;
      left: 15px;
    }


    a.quobal__logo__text {
      display: none;
    }

    &.topbar__info {
      width: auto;
      right: 0;
      z-index: 103;
    }
  }

  .topbar__search {
    display: none;
  }

  .topbar__search-wrap {
    display: none;
  }

  .mobile__search {
    .topbar__search-wrap {
      display: block;
    }
  }

  .topbar__btn.mobile__search_btn {
    display: block;
  }

  .topbar__btn.topbar__btn--new .topbar__btn-new-label {
    top: 10px;
  }

  /* .mobile__bottombar{
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    min-height: 60px;
    background-color: $color-accent;
    border-top: 5px solid $color-white;
    z-index: 10;

    .topbar__collapse,
    .topbar__quo,
    .topbar__balance,
    .mobile__search_btn{
      margin: auto;
      
    }

    .topbar__btn,
    .topbar__btn--mail,
    .mobile__search_btn{
      color: $color-black;

      &:hover{
        background-color: transparent;
        color: $color-blue;
      }
    }
    .topbar__quoitem{
      height: 52px;
      width: 52px;
      background-color: $color-blue;
      border-radius: 100%;
      margin-top: -100%;
      padding: 10px;
      //border: 3px solid $color-black;
      box-shadow: 3px 3px 0 $color-black;
      &:hover{
        background-color: $color-blue-hover;
      }
      
    }
    .icon-quo{
      width: 100%;
      height: 100%;
      background-image: url(#{$assetPath}/e5a94d28-8b3d-422f-8d9e-6a4314433cf5.svg);
      background-size: cover;
      margin: auto;
    }
  } */
}

@media screen and (max-width: 640px) {
  .topbar__logo {
    width: 35px;
    background-size: 100%;
  }

  .topbar__greeting {
    margin-left: 10px;
  }

}


@media screen and (max-width: 576px) {


  .topbar__profile {
    margin: inherit;
  }

  .topbar__collapse {

    &:last-child {
      margin-right: 0;
    }

    &:not(last-child) {
      margin: inherit;
    }
  }

  .topbar__collapse-content {
    width: 250px;
    right: -215px;
  }



  .topbar__button {

    &.topbar__button--desktop {
      display: none;
    }
  }

  .topbar.topbar--navigation {

    &.topbar__button--desktop {
      display: none;
    }
  }
}

@media screen and (max-width: 320px) {

  .topbar__right {
    justify-content: flex-end;
  }

  .topbar__search {
    margin-left: 140px;
  }

  .topbar__profile {
    margin: auto 0;
  }

  .topbar__collapse-content {
    width: 230px;
    right: -210px;
  }

  .topbar__menu-wrap {
    right: -50px;
  }
}


.mobile__bottombar {
  display: flex;

  .mobile__search {
    .topbar__search-field {
      &.MuiFormControl-root {
        //border-color: $color-black;
        background-color: $color-black;
      }
    }
  }

  .mobile__search-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    background-color: $color-accent;
    padding-left: $siteMargin;
    padding-right: $siteMargin;
    transition: all 0.3s;
    display: none;
    opacity: 0;

    &.open {
      display: flex;
      opacity: 1;
    }


    .MuiAutocomplete-root {
      margin: auto;
    }

    .MuiAutocomplete-input {
      width: 200px;
    }


    .mobile__search-close {
      position: absolute;
      right: 15px;
      top: 15px;
      color: $color-white;
      font-size: 18px;
      border-radius: 100%;
      padding: 4px 6px;
      border: 0;
      background-color: transparent;

      &:hover {
        background-color: $color-black;
      }
    }
  }

}