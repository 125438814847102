.landing_logo__container {
  min-height: 200px;
  padding-bottom: 0;
}

.landing_s {
  // overflow-x: hidden;

  .landing-logo {
    width: 420px;
    height: 130px;
    text-align: center;
    background: url(#{$frontendPath}/img/quobal-logo-horizontal-rgb.svg) no-repeat center;
    background-size: cover;
    text-indent: 100%;
    overflow: hidden;
    margin: auto;
    position: sticky;
    top: 50px;
    left: 0;
    z-index: 10;
    transition: all linear .3s;

    &.small {
      transform: scale(50%);
      top: 0;
    }
  }

  .landing__section {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  p {
    font-size: 18px;
  }

  .subhead {
    font-size: 54px;
    line-height: 1.2;
    font-weight: $font-xbold;
    padding-left: 0;
  }

  @media screen and (max-width: 768px) {
    .landing-logo {
      width: 80%;
      height: 150px;
      margin-top: 0;
    }
  }

  .ls__dialogue__section {
    min-height: 600px;
    position: relative;
    margin-top: 60px;

    .ls__bg1 {
      width: 40vw;
      height: 450px;
      text-align: center;
      background: url(#{$frontendPath}/img/ls_bg1.svg) no-repeat center;
      background-size: contain;
      position: absolute;
      top: -120px;
      z-index: 1;
      transform: scale(96%);
      animation: ls_bg1_ani 2s linear infinite;
    }

    @keyframes ls_bg1_ani {
      50% {
        transform: scale(98%);
      }

      100% {
        transform: scale(96%);
      }
    }

    .ls__bg2 {
      width: 40vw;
      height: 500px;
      text-align: center;
      background: url(#{$frontendPath}/img/ls_bg2.svg) no-repeat center;
      background-size: contain;
      position: absolute;
      top: -150px;
      z-index: 5;
      animation: ls_bg2_ani 3s linear infinite;
    }

    @keyframes ls_bg2_ani {
      50% {
        transform: scale(98%);
      }

      100% {
        transform: scale(100%);
      }
    }

    .ls__header__container {
      position: absolute;
      width: 40vw;
      right: 60px;
      top: 150px;

      .ls__header {
        font-size: 54px;
        line-height: 1.2;
        font-weight: $font-xbold;
        color: $color-black;
        text-align: center;
        position: relative;
        z-index: 4;
      }

      span {
        display: block;
        text-shadow: $color-accent 3px 3px 0;
      }
    }

    .btn-toolbar {
      padding: 0 4vw;
    }

    .btn-close {
      width: 20px;
      height: 20px;
      text-align: center;
      background-color: $color-light-gray;
      box-shadow: 2px 2px 0 $color-black;
      padding: 0;
      border-radius: 0;

      &:hover {
        box-shadow: none;
        color: $color-white;
        border-color: $color-black;
      }

      &:before {
        background-color: $color-red;
      }
    }

    .btn-dialogue {
      border-radius: 0;
      box-shadow: $color-black 2px 2px 0;
      background-color: $color-white;
      color: $color-black;
      border: 2px solid;

      &:hover {
        color: $color-white;
        border-color: $color-black;
      }

      &.btn-accept:before {
        background-color: $color-green;
      }

      &.btn-reject:before {
        background-color: $color-red;
      }
    }

    .main-box {
      width: 100%;
      border-radius: 15px;
      border: 2px solid;
      padding: 30px;
      padding-top: 50px;
      position: relative;
      background-color: $color-white;
      overflow: hidden;

      p {
        font-size: 16px;
      }
    }

    .dialog_topbar {
      height: 40px;
      background-color: #BFBFBF;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }

    .quo__badge {
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      margin-top: 20px;

      .quo__badge-pic {
        width: 30px;
        height: 30px;
        display: inline-block;
        margin: 0;
      }

      .quo__price {
        display: inline-block;
        margin-left: 10px;
        font-size: 18px;
      }
    }

    .dialog_box_container {
      width: 40vw;
      margin-top: 4vw;
      margin-left: 5vw;
    }

  }

  .ls__section1_b {
    margin-top: 50px;

    .subhead {
      position: relative;
      text-align: center;

      span {
        // display: block;
        text-shadow: $color-accent 3px 3px 0;
      }

      .small {
        font-size: 34px;
        font-weight: inherit;
      }
    }

    /* Marquee styles */
    .landing__s__marquee {
      --gap: 1rem;
      position: relative;
      display: flex;
      overflow: hidden;
      user-select: none;
      gap: var(--gap);
      margin: 0 -15px;
      min-width: 100vw;
      height: 280px;
    }

    .landing__s__marquee__content {
      align-items: stretch;
      flex: none;
      flex-shrink: 0;
      display: flex;
      grid-column-gap: 30px;
      justify-content: space-around;
      gap: var(--gap);
      min-width: 100%;
      animation: scroll 50s linear infinite;
      -webkit-animation: scroll 50s linear infinite;
      height: 260px;

      .landing__s__item {
        margin: 0;
        padding: 0;
        flex-direction: column;
        flex: none;
        align-items: center;
        border: 0;
        width: 150px;
        height: 100%;
        display: flex;

        img {
          &:hover {
            width: 105%;
            transition: all .2s;
          }
        }
      }

      .landing__s__item {
        border-radius: 0;
        background: transparent;

        img {
          border-radius: 15px;
        }

        .landing__s__name {
          color: $color-black;
          font-weight: $font-xbold;
          align-items: flex-end;
        }

        .landing__s__title {
          font-size: 13px;
          color: #999;
        }

        .landing__s__earn {
          font-size: 14px;
        }
      }
    }

    @keyframes scroll {
      from {
        transform: translateX(0);
      }

      to {
        transform: translateX(calc(-100% - var(--gap)));
      }
    }

    .landing__s__marquee__wrapper {
      position: relative;
      overflow: hidden;
      margin: 0 -15px;
      min-width: 100vw;
      height: 280px;
    }


    // .landing__s__marquee {
    //   align-items: stretch;
    //   flex: none;
    //   justify-content: flex-start;

    //   .landing__s__track {
    //     height: 260px;
    //     position: absolute;
    //     white-space: nowrap;
    //     will-change: transform;
    //     display: flex;
    //     grid-column-gap: 30px;
    //     justify-content: flex-start;
    //     align-items: flex-start;
    //     animation: marquee 50s linear infinite;
    //     -webkit-animation: marquee 50s linear infinite;
    //     // background-color: #000;

    //     .landing__s__item {
    //       margin: 0;
    //       padding: 0;
    //       flex-direction: column;
    //       flex: none;
    //       align-items: center;
    //       border: 0;
    //       width: 150px;
    //       height: 100%;
    //       display: flex;

    //       img {
    //         &:hover {
    //           width: 105%;
    //           transition: all .2s;
    //         }
    //       }
    //     }
    //   }
    // }

    // @keyframes marquee {
    //   0% {
    //     transform: translateX(0);
    //   }

    //   100% {
    //     transform: translateX(-50%);
    //   }
    // }

    // .landing__s__item {
    //   border-radius: 0;
    //   background: transparent;

    //   img {
    //     border-radius: 15px;
    //   }

    //   .landing__s__name {
    //     color: $color-black;
    //     font-weight: $font-xbold;
    //     align-items: flex-end;
    //   }

    //   .landing__s__title {
    //     font-size: 13px;
    //     color: #999;
    //   }

    //   .landing__s__earn {
    //     font-size: 14px;
    //   }
    // }
  }

  .ls__section2 {
    .ls_visual1 {
      width: 100%;
      height: 500px;
      text-align: center;
      background: url(#{$frontendPath}/img/ls_visual1.png) no-repeat center;
      background-size: contain;
      // animation: ls_visual1_ani 3s linear infinite;
      transform: rotate(-2deg);
    }

    @keyframes ls_visual1_ani {
      50% {
        transform: rotate(2deg);
      }

      100% {
        transform: rotate(-2deg);
      }
    }

    .ls_visual2 {
      width: 100%;
      height: 500px;
      text-align: center;
      background: url(#{$frontendPath}/img/ls_visual2.png) no-repeat center;
      background-size: contain;
      // animation: ls_visual2_ani 3s linear infinite;
      transform: rotate(3deg);
      position: relative;
      top: -120px;
    }

    @keyframes ls_visual2_ani {
      50% {
        transform: rotate(-2deg);
      }

      100% {
        transform: rotate(2deg);
      }
    }

    .subhead {
      position: relative;

      span {
        display: block;
        text-shadow: $color-accent 3px 3px 0;
      }

      .small {
        font-size: 34px;
        font-weight: inherit;
      }

      &.subhead2 {
        margin-left: 100px;
      }
    }

    p {
      margin-top: 40px;
    }
  }

  .ls__section3 {
    background-color: $color-accent;
    align-items: flex-end;
    padding-top: 80px;
    padding-bottom: 80px;

    .subhead {
      position: relative;
      margin-bottom: 40px;
      padding: 0;

      span {
        display: block;
        text-shadow: $color-blue 3px 3px 0;
      }

      .small {
        font-size: 34px;
        font-weight: inherit;
      }

      &.subhead1 {
        padding-left: 0;
      }
    }

    .ls_visual_girl {
      width: 100%;
      height: 640px;
      text-align: center;
      background: url(#{$frontendPath}/img/ls_visual_girl.png) no-repeat center bottom;
      background-size: contain;
      position: absolute;
      bottom: -80px;
    }

    .ls_visual_girl_bubble {
      width: 100%;
      height: 440px;
      text-align: center;
      background: url(#{$frontendPath}/img/ls_visual_girl_bubble.svg) no-repeat center;
      background-size: contain;
      position: absolute;
      bottom: 140px;
    }

    .btn-toolbar {
      margin-bottom: 30px;
    }
  }

  footer {
    background-color: $color-dark-gray;
    margin-left: -15px;
    margin-right: -15px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    color: $color-white;
    position: relative;
    padding: 20px 10px;

    .footer__group{
      display: flex;
    }

    .footer-links {
      margin: 0;
      position: relative;
      z-index: 2;

      li {
        padding: 0 10px;
        margin: 0;
        color: $color-white;
      }

      a {
        color: $color-white;

        &:hover {
          color: $color-accent;
        }
      }

      i {
        font-size: 18px;
      }
    }

    .copyright {
      margin-left: 30px;
      margin-right: 20px;
      color: $color-white;
      padding: 0;
    }

    .caption {
      margin-right: 30px;
      margin-left: 15px;
    }
  }

  .ls__section4 {
    text-align: center;
    background: linear-gradient(rgba(82, 166, 191, .75), rgba(82, 166, 191, .5)),
    url(#{$frontendPath}/img/ls_s4_bg.jpg);
    background-position: center 30%;
    background-repeat: no-repeat;
    background-size: cover;

    .subhead {
      color: $color-white;
    }
  }


  @media screen and (min-width: 1401px) {
    .ls__dialogue__section {
      .dialog_box_container {
        width: 600px;
      }

      .ls__header__container {
        width: 50%;
      }

      .ls__bg1,
      .ls__bg2 {
        width: 100%;
      }
    }
  }


  @media screen and (max-width: 992px) {
    .subhead {
      font-size: 48px;
    }

    .ls__dialogue__section {
      .dialog_box_container {
        width: 72vw;
      }

      .ls__header__container {
        width: 100%;
        height: 450px;
        position: relative;
        right: 0;
        margin-top: 400px;
        text-align: center;

        .ls__header {
          font-size: 48px;
        }
      }

      .ls__bg1,
      .ls__bg2 {
        width: 100%;
        left: 0;
      }

      .main-box {
        p {
          font-size: 14px;
        }
      }

      .btn-toolbar {
        padding: 0 15px;

        .btn {
          font-size: 14px;
          padding: 5px 15px;
        }
      }
    }

    .ls__section2 {
      .subhead {
        margin-top: 30px;

        &.subhead2 {
          margin-left: 40px;
        }
      }

      .ls_visual1 {
        height: 320px;
      }
    }

    .ls__section3 {
      .ls_visual_girl {
        height: 580px;
      }
    }

    .ls__section4 {
      .subhead {
        font-size: 36px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    p {
      font-size: 16px;
    }

    .ls__dialogue__section {
      .ls__header__container {
        height: 400px;
        margin-top: 350px;

        .ls__header {
          font-size: 36px;
        }
      }

      .ls__bg1,
      .ls__bg2 {
        height: 400px;
      }
    }

    .ls__section2 {
      .subhead {
        margin-left: 6vw;

        .bold {
          font-size: 48px;
        }

        &.subhead2 {
          margin-left: 15vw;
        }
      }

      .ls_visual1 {
        margin-top: 50px;
        height: 250px;
      }

      .ls_visual2 {
        height: 300px;
        top: -50px;
      }
    }

    .ls__section3 {
      padding-top: 60px;
      padding-bottom: 400px;


      .visual_container {
        position: static;
      }

      .ls_visual_girl {
        height: 400px;
        left: 0;
      }

      .ls_visual_girl_bubble {
        height: 300px;
        bottom: 100px;
        left: 0;
      }
    }

    footer {
      padding-bottom: 60px;
      position: relative;

      .caption {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 40px;
        padding: 20px 0;
        text-align: center;
      }

      .copyright {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        text-align: center;
        margin: 0 30px;
      }

      .footer-links {
        margin: auto;
        margin-bottom: 10px;
      }
    }
  }
}