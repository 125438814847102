.form__edit_avatar {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: #dddddd;
  margin: auto;
  position: relative;

  img {
    border-radius: 100%;
  }

  .las {
    width: 14px;
    height: 14px;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &:hover {
    cursor: pointer;
  }
}

.otp__container {
  width: 100%;
  margin: 0 auto;
  display: flex;
}

.otp__input {
  margin: 0 auto !important;
  width: 35px !important;
  height: 35px !important;
  padding: 0 !important;
  font-size: 20px !important;
  text-align: center !important;
  margin-right: 12px !important;
  text-transform: uppercase !important;
  color: #494949 !important;
  font-family:
    SF Pro Text,
    SF Pro Icons,
    Helvetica Neue,
    Helvetica,
    Arial,
    sans-serif !important;
  border: 1px solid #d6d6d6 !important;
  border-radius: 4px !important;
  background: #fff !important;
  background-clip: padding-box !important;
}

.form {
  display: flex;
  flex-wrap: wrap;

  input,
  textarea {
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    height: 32px;
    // background: transparent;

    &::-webkit-input-placeholder {
      color: $color-additional;
    }

    &::-moz-placeholder {
      color: $color-additional;
    }

    /* Firefox 19+ */
    &:-moz-placeholder {
      color: $color-additional;
    }

    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: $color-additional;
    }

    &[disabled] {
      @include themify($themes) {
        background: themed("colorBackgroundBody");
        opacity: 0.8;

        &::placeholder {
          color: #aaaaaa;
        }
      }

      &:focus,
      &:active {
        @include themify($themes) {
          border-color: themed("colorBorder");
        }
      }
    }
  }

  textarea {
    min-height: 56px;
  }

  select {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    background-color: transparent;
    width: 100%;

    background: url(#{$assetPath}/7e4fec48-948e-4630-831e-65c25034f81f.svg) center no-repeat;
    background-position: right 10px center;
    background-size: 10px;
    padding: 0 10px;
    margin: 0;
    height: 32px;
    font-size: 12px;
  }

  input,
  textarea,
  select {
    transition: all 0.3s;
    border: 1px solid $color-fields-border;
    color: $color-black;
    background-color: $color-white;

    &:focus,
    &:active {
      outline: none;
      border-color: $color-blue;
      box-shadow: none;
    }
  }

  .ql-editor {
    transition: all 0.3s;
    min-height: 100px;
    padding: 5px 10px;
    border: 1px solid $color-fields-border;
    border-radius: 0.25rem;
    // color: $color-black;

    &:focus,
    &:active {
      outline: none;
      border-color: $color-blue;
      box-shadow: none;
    }
  }

  &.form--horizontal {
    .form__form-group {
      display: flex;
      flex-wrap: wrap;
    }

    .form__form-group-field {
      width: calc(100% - 80px);
      padding-left: 10px;
      margin-left: 80px;
    }

    .form__form-group-label {
      width: 80px;
      max-height: 32px;
      line-height: 18px;
      margin: auto 0;

      & + .form__form-group-field {
        margin-left: 0;
      }
    }

    .form__form-group-description {
      margin-left: 90px;
    }

    .form__button-toolbar {
      margin-left: 0;
    }

    .form__form-group-input-wrap--error-above {
      margin-bottom: 15px;

      .form__form-group-error {
        top: -28px;
      }
    }

    @media screen and (min-width: 480px) {
      .form__form-group-label {
        width: 120px;
      }

      .form__form-group-field {
        width: calc(100% - 120px);
        margin-left: 120px;
        padding-left: 20px;
      }

      .form__form-group-description,
      .form__button-toolbar {
        margin: auto;
      }
    }
  }

  &.form--preview {
    display: flex;

    & > div:nth-child(2) {
      margin-right: 50px;
    }

    .form__form-group {
      margin-bottom: 10px;
      width: auto;
      min-height: 18px;
    }

    .form__select-color {
      display: flex;
      flex-wrap: wrap;
      max-width: 84px;
      margin-right: 40px;
    }

    p {
      margin-bottom: 10px;
    }
  }

  &.form--justify {
    display: flex;
    flex-wrap: wrap;

    .form__form-group {
      width: 33.3333%;
    }

    .form__button-toolbar {
      width: 100%;
    }
  }

  .form__form-group-select {
    width: 100%;
    height: 32px;
    font-size: 12px;

    .Select-control {
      height: 32px;
      border-radius: 0;
      transition: all 0.3s;
      background: transparent;

      @include themify($themes) {
        border: 1px solid themed("colorFieldsBorder");
      }
    }

    .Select-placeholder,
    .Select-input {
      height: 30px;
    }

    .Select-input {
      width: 100%;
    }

    input {
      width: 100% !important;
      border: none;
      padding: 0;
    }

    .Select-multi-value-wrapper {
      .Select-input {
        width: inherit;
      }

      .Select-value {
        background: transparent;
        border-color: $color-blue;
      }

      .Select-value-label {
        @include themify($themes) {
          color: themed("colorText") !important;
        }
      }

      .Select-value-icon {
        border-color: $color-blue;
      }
    }

    .Select-menu-outer {
      top: calc(100% + 1px);
      border-radius: 0;
      box-shadow: none;
      font-size: 12px;
      animation: open 0.3s ease-in-out;
      overflow: hidden;

      @include themify($themes) {
        border: 1px solid themed("colorBorder");
        background: themed("colorBackground");
      }
    }

    @keyframes open {
      0% {
        max-height: 0;
      }

      100% {
        max-height: 200px;
      }
    }

    .Select-option {
      transition: all 0.3s;
      border-radius: 0;
      display: flex;

      @include themify($themes) {
        background: themed("colorBackground");
        color: themed("colorText");
      }

      &.is-focused {
        @include themify($themes) {
          background: themed("colorHover");
        }
      }
    }

    &.is-focused,
    &.is-focused:not(.is-open) {
      .Select-control {
        border-color: $color-accent;
        box-shadow: none;
        background: transparent;
      }
    }

    .form__form-group-select-color {
      display: block;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      margin: auto 0 auto 5px;
    }
  }
}

.quo__form {
  .form__form-group-label {
    color: $color-white;
    display: none;
  }

  .form__form-group-field input,
  .form__form-group-field textarea {
    background-color: rgba(0, 0, 0, 0.2);
    color: $color-white;

    &::placeholder {
      color: $color-white;
    }

    &:focus {
      border-color: $color-light-gray;
      background-color: $color-black;
    }
  }

  .ql-container {
    font-size: 12px;
    color: $color-white;
  }

  .ql-editor {
    border: 1px solid $color-fields-border;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.2);
    color: $color-white;

    &:focus {
      border-color: $color-light-gray;
      background-color: $color-black;
    }

    p {
      color: inherit;
    }
  }

  input,
  textarea,
  select {
    background-color: $color-white;

    &:active {
      border-color: $color-black;
    }
  }

  .form__form-group-price {
    width: 70%;

    .form__form-group-field {
      margin-left: 0;
    }
  }

  .form__form-group-socialmeia {
    display: flex;
    gap: 5px;

    label {
      width: 20px;
      color: $color-white;
      margin-right: 10px;

      .lab {
        font-size: 20px;
      }
    }

    .form__form-group-field {
      margin-left: 0;
    }

    .form__form-group-input-wrap {
      background-color: rgba(255, 255, 255, 0.2);
      border: 1px $color-light-gray solid;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      font-size: 12px;
      color: $color-white;

      input {
        display: inline-block;
        background-color: transparent;
        border: 0;
        padding-left: 0;
        color: $color-white;

        &::placeholder {
          color: $color-light-gray;
        }
      }
    }

    .control-btn {
      font-size: 18px;
    }

    svg {
      fill: $color-white;
      width: 18px;
      height: 18px;
      margin: 0;
    }

    .icon-twitter {
      width: 16px;
      height: 16px;
      margin-top: 6px;
    }
  }
}

.counter__form {
  .ql-container {
    font-size: 12px;
  }

  .ql-editor {
    border: 1px solid $color-fields-border;
    border-radius: 5px;
    background-color: $color-white;
    transition: all 0.3s;

    &:focus {
      border-color: $color-blue;
    }

    p {
      color: inherit;
    }
  }
  .form__form-group-label {
    display: none;
  }

  .btn {
    margin-bottom: 0;
    margin-left: inherit;
    margin-right: inherit;
  }
}

.payout__form {
  .btn {
    margin-bottom: 0;
  }
}

.payment__form {
  .btn-toolbar {
    margin-top: 20px;
  }
}

.payment__btn {
  justify-content: center;
}

.form__half {
  width: calc(50% - 15px);
  height: 100%;

  &:first-child {
    margin-right: 30px;
  }

  .form__button-toolbar {
    float: right;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-right: 0;
  }
}

.form__form-group {
  margin-bottom: 20px;
  width: 100%;
  position: relative;
}

.form__form-group--address {
  input:last-child {
    margin-left: 15px;
    width: 70%;
  }
}

.form__form-group--section {
  width: 100%;
  position: relative;
}

.form__form-group-field {
  width: 100%;
  display: flex;
  margin: auto;
}

.form__form-group-label {
  margin-bottom: 4px;
  display: inline-block;
  color: $color-black;

  span {
    color: #dddddd;
  }
}

.form__form-group-button {
  padding: 6px;
  height: 32px;
  cursor: pointer;
  transition: all 0.3s;
  background: $color-fields-border;
  border: 1px solid $color-fields-border;

  /* @include themify($themes) {
    background: themed('colorFieldsBorder');
    border: 1px solid themed('colorFieldsBorder');
  } */

  svg {
    fill: $color-additional;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }

  &.active {
    background: $color-accent;
    border: 1px solid $color-accent;

    svg {
      fill: #ffffff;
    }
  }
}

.form__form-group-file {
  label {
    border-radius: 2px;
    line-height: 18px;
    font-size: 12px;
    padding: 4px 20px;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;

    @include themify($themes) {
      border: 1px solid themed("colorFieldsBorder");
      color: themed("colorText");
    }

    &:hover {
      background: $color-fields-border;
    }
  }

  span {
    padding-left: 10px;
  }

  input {
    display: none;
  }
}

.form__form-group-icon {
  padding: 6px;
  height: 32px;
  background: $color-fields-border;
  border: 1px solid $color-fields-border;

  /* @include themify($themes) {
    background: themed('colorFieldsBorder');
    border: 1px solid themed('colorFieldsBorder');
  } */

  svg {
    fill: $color-additional;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }
}

.form__form-group-description {
  font-size: 10px;
  color: $color-additional;
  line-height: 13px;
  margin-top: 2px;
}

.form__button-toolbar {
  margin-top: 10px;
}

.form__form-group-input-wrap {
  width: 100%;

  .hHGUII:hover {
    .sc-breuTD {
      color: $color-blue;
    }
    .sc-crXcEl {
      border-color: $color-blue;
    }
  }

  .emnuni {
    margin-right: 10px;
  }
  .eMxwId:checked + .sc-crXcEl {
    border-color: $color-blue;

    &:before {
      left: calc(50% - 3px);
      background: $color-blue;
    }
  }
}

.form__form-group-error {
  font-size: 10px;
  line-height: 13px;
  color: #ad4444;
  margin-bottom: 5px;
  display: block;
  margin-top: 5px;
}

.form__form-group-input-wrap--error-above {
  .form__form-group-error {
    position: absolute;
    margin: 0;
    right: 0;
    top: 3px;
    padding: 5px 10px;
    background: #ffbcbc;
    border-radius: 3px;

    &:after {
      content: "";
      position: absolute;
      right: 10px;
      bottom: -8px;
      border: 4px solid transparent;
      border-top: 4px solid #ffbcbc;
    }
  }
}

.form__form-group-date-cvc {
  display: flex;
  width: 100%;

  .form__form-group-date {
    width: 100%;
    margin-right: 20px;
  }

  .form__form-group-cvc {
    max-width: 280px;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-date {
      margin-right: 0;
    }

    .form__form-group-cvc {
      max-width: 100%;
    }
  }
}

.form__form-group-id-category {
  width: 100%;
  display: flex;

  .form__form-group-id {
    min-width: 100px;
    width: 40%;
    margin-right: 20px;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-id {
      margin-right: 0;
      width: 100%;
    }
  }
}

.form__form-group-price-discount {
  width: 100%;
  display: flex;
  margin-bottom: 20px;

  & > div {
    margin-bottom: 0;
  }

  .form__form-group-price {
    margin-right: 20px;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-price {
      margin-right: 0;
    }
  }
}

.account-setting-form {
  .form__form-group {
    margin-left: 80px;
    margin-right: 80px;
  }

  .btn-toolbar {
    margin: auto;
  }
}

.form.form-edit_profile {
  .form__form-group-description {
    display: inline-block;
    margin-top: 15px !important;
  }
}

.form__form-qrcode {
  display: flex;
  justify-content: center;
  margin: 15px 0;
}

.form__form-qrcode_code {
  text-align: center;
  margin: 15px 0;
}

.login-form {
  .form__form-group-error {
    top: -15px;
  }
}

.quill {
  .ql-tooltip,
  .ql-toolbar {
    display: none;
  }
}
