// .container {

//   @media screen and (min-width: 768px) {
//     width: 100%;
//     max-width: 1630px;
//   }

//   @media (min-width: 576px){
//     max-width: none;
//   }

// }

// .container__wrap {
//   min-height: 100vh;
//   transition: padding-left 0.3s;
//   padding-top: $siteTopMargin;
//   padding-left: $siteMargin;
//   padding-right: $siteMargin;

//   @include themify($themes) {
//     background: themed('colorBackgroundBody');
//   }
//   @media screen and (max-width: 768px) {
//     padding-left: $siteMobileMargin;
//     padding-right: $siteMobileMargin;
//     padding-top: 20px;
//     // min-height: calc(100vh - 60px);
//   }

// }

.container {

  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 1630px;
  }
}

.container__wrap {
  padding-left: 0;
  // padding-top: 30px;
  // padding-bottom: 30px;
  min-height: 100vh;
  transition: padding-left 0.3s;
  overflow: hidden;

  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }

  @media screen and (max-width: 768px) {
    padding-top: 0;
    // height: auto;
    // min-height: -webkit-fill-available;
  }

  @media screen and (min-width: 576px) {
    padding-left: 250px;

    &.nosidebar{
      padding-left: 0;
    }
  }
  
}

.layout {

  &.layout--collapse {

    & + .container__wrap {
      padding-left: 0;

      @media screen and (min-width: 992px) {
        padding-left: 240px;
      }

      @media screen and (min-width: 2110px) {
        padding-left: 0;
      }
    }
  }

  &.layout--top-navigation{

    & + .container__wrap{

      @media screen and (min-width: 576px) {
        padding-left: 0;
      }
    }
  }

}



@keyframes ldio-vbm0w6sebpp {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-vbm0w6sebpp div {
  position: absolute;
  width: 40px;
  height: 40px;
  border: 6px solid #fff;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-vbm0w6sebpp div {
  animation: ldio-vbm0w6sebpp 1s linear infinite;
  top: 40px;
  left: 40px;
}
.loadingio-spinner-rolling-h0gdcpc6y5q {
  width: 100px;
  height: 100px;
  display: inline-block;
  overflow: hidden;
  margin: auto;
}
.ldio-vbm0w6sebpp {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-vbm0w6sebpp div { box-sizing: content-box; }
/* generated by https://loading.io/ */




@keyframes ldio-bmb7bj2mvvr {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}
.ldio-bmb7bj2mvvr > div > div {
  transform-origin: 100px 100px;
  animation: ldio-bmb7bj2mvvr 3.0303030303030303s linear infinite;
  opacity: 0.8
}
.ldio-bmb7bj2mvvr > div > div > div {
  position: absolute;
  left: 30px;
  top: 30px;
  width: 70px;
  height: 70px;
  border-radius: 70px 0 0 0;
  transform-origin: 100px 100px
}.ldio-bmb7bj2mvvr > div div:nth-child(1) {
  animation-duration: 0.7575757575757576s
}
.ldio-bmb7bj2mvvr > div div:nth-child(1) > div {
  background: #1d0e0b;
  transform: rotate(0deg);
}.ldio-bmb7bj2mvvr > div div:nth-child(2) {
  animation-duration: 1.0101010101010102s
}
.ldio-bmb7bj2mvvr > div div:nth-child(2) > div {
  background: #4daccf;
  transform: rotate(0deg);
}.ldio-bmb7bj2mvvr > div div:nth-child(3) {
  animation-duration: 1.5151515151515151s
}
.ldio-bmb7bj2mvvr > div div:nth-child(3) > div {
  background: #efea43;
  transform: rotate(0deg);
}.ldio-bmb7bj2mvvr > div div:nth-child(4) {
  animation-duration: 3.0303030303030303s
}
.ldio-bmb7bj2mvvr > div div:nth-child(4) > div {
  background: #e4e5e6;
  transform: rotate(0deg);
}
.loadingio-spinner-wedges-8u6m9qovka {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  margin: auto;
}
.ldio-bmb7bj2mvvr {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-bmb7bj2mvvr div { box-sizing: content-box; }
/* generated by https://loading.io/ page load */

