.quo__history__container {
  &.container {
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.quo__history {
  display: flex;
  gap: 15px;

  .small-text {
    margin-top: 5px;
  }

  .card__title {
    margin-bottom: 10px;
  }

  .back {
    margin-bottom: 15px;

    .btn {
      margin: 0;
      font-size: 14px;
    }

    a {
      color: $color-black;

      &:hover {
        color: $color-blue;
      }
    }

    i {
      margin-right: 2px;
    }
  }

  .quo__history-quo-status {
    border-top: 1px $color-light-gray solid;
    border-bottom: 1px $color-light-gray solid;
    margin-bottom: 20px;
    justify-content: center;
    text-align: center;

    .quo__status {
      margin: 20px 0;
    }

    .quo__btns {
      margin-bottom: 20px;
      height: auto;
      justify-content: space-evenly;
    }
  }

  .quo__info-right {

    strong {
      display: block;
    }
  }

  .quo__info {
    margin-top: 0;
    font-size: 12px;
    display: block;

    .bold-text {
      font-size: 18px;
    }

    .quo__from{
      text-align: center;
    }

    .quo__delivery {
      margin-bottom: 0;
    }
  }

  .quo__badge{
    width: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;

    .quo__badge-pic{
      width: 70px;
      height: 70px;
    }

    .quo__price{
      font-size: 18px;
    }
  }

}


.quo__history__detail {
  width: 40%;

  .quill {
    margin-bottom: $elementMargin;
  }

  .quo__status-name {
    a {
      color: inherit;
    }
  }
}

.quo__history__timeline {
  width: 60%;

  .timeline {
    margin-bottom: 0;
  }

  .timeline__item{
    padding-bottom: 0;
  }

  .timeline__content {
    background-color: $color-white;

    &:after {
      border-left-color: transparent;
      border-left-color: transparent;
    }
  }

  .timeline .timeline__item:nth-child(even) {

    .timeline__content {
      &:after {
        border-left-color: transparent;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .quo__history__container {
    &.container {
      padding-top: $siteTopMobileMargin;
    }
  }
}

@media screen and (max-width: 768px) {
  .quo__history {
    display: block;

    .card-body {
      box-shadow: none;
      border-radius: 0;
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  .quo__history__detail,
  .quo__history__timeline {
    width: 100%;

  }
}