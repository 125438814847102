// .container__wrap{
//   .open__call__container{

//   }
// }

.open__call__container{
  min-height: 100vh;

  .col{
    padding: 0;
  }

  .quobal__logo__container {
    position: fixed;
    bottom: 15px;
    left: 15px;
    z-index: 10;
    height: 50px;
    font-weight: $font-bold;

    a {
      display: inline-block;
      color: $color-black;
    }
  }

  .row{
    justify-content: center;
  }

  .open__call-filter{
    background-color: $color-white;
    margin-bottom: 15px;
    // border-radius: 100px;
    padding: 0 10px;
    position: fixed;
    right: 20px;
    top: 200px;
    z-index: 10;
    display: flex;
    flex-direction: column;

    .btn{
      margin: 10px 5px;
      padding: 8px 15px;
    }
  }
  .open__call-group{
    //display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    height: 100vh;
    overflow-y: scroll;
    // min-height: calc(100vh - 65px);
    // border: calc($card-border-weight / 2) solid $color-black;

  }



  @media screen and (max-width: 992px) {
    

    .quobal__logo__container {
      display: none;
    }
    .open__call-group{
      padding-bottom: 65px;
    }
  }
  .open__call-flow{
    position: fixed;
    bottom: 0;
    left: 240px;
    right: 0;
    z-index: 10;


    .alert{
      height: 65px;
      display: block;
      margin: 0;
      font-size: 12px;
      padding-right: 200px;
      i{
        font-size: 40px;
        float: left;
        height: 50px;
      }

    }
    .btn{
      margin: 0;
      padding: 10px 15px;
      position: fixed;
      bottom: 10px;
      right: 40px;

      .icon-quo{
        width: 18px;
        height: 18px;
        overflow: hidden;
        background: url(#{$assetPath}/6adee41e-b113-4fb8-ac27-4b2583b3d00c.svg) center;
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 5px;
      }
    }

  @media screen and (max-width: 992px) {
    left: 0;
    width: 100%;

    .alert{
      height: auto;
      padding-right: 15px;
      padding-bottom: 60px;
      i{
        font-size: 54px;
      }
    }
    .btn{
      width: 160px;
      // right: calc(50vw - 80px);
      right: 15px;
    }
  }
  }


  .card{
    padding-bottom: 0;

    &.open__call-creator__card{
      .panel__top{
        background-color: $color-gray;
      }
    }

    .card-body{
      width: 100%;
      display: block;
      box-shadow: none;
      border: calc($card-border-weight / 2) solid $color-medium-gray;
      overflow: hidden;
      border-radius: 0;
      text-align: center;
    }

    .btn-toolbar{
      justify-content: center;
      margin-bottom: 20px;
      
    }
  }
  .quo__body{
    padding-right: 20px;
    padding-bottom: 120px;
    padding-top: 40px;

    .quo__title{
      font-weight: $font-bold;
      font-size: 15px;
      text-align: center;
      position: relative;
      margin: 10px 0;
    }
    .quo__delivery{
      text-align: center;
      position: relative;
      font-size: 10px;
      display: inline-block;
    }
    .btn-toolbar{
      position: absolute;
      bottom: 50px;
      left: 0;
      margin: 0;
      width: 100%;
    }
    .quo__status-avatar{
      margin-right: -10px;
    }
    .open__call-enroll{
      position: absolute;
      bottom: 15px;
      font-size: 10px;
      
      span{
        margin-left: 5px;

        &.open__call-num{        
          margin-left: 20px;
        }
      }
    }
    .open__call-link{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      border-radius: 0;
      border: 0;
      z-index: 1;

      &.btn-secondary:active, &::before{
        background-color: transparent;
      }
    }
  }
  .panel__top{
    padding: 10px;
    margin-left: -20px;
    margin-right: -40px;
    margin-top: -20px;
  }
  .quo__badge{
    display: flex;
    margin-right: 0;
    width: auto;
    align-items: center;
    justify-items: center;
    position: relative;

    .quo__badge-pic{
      margin-right: 0;
      margin-left: auto;
    }

    .quo__price{
      font-size: 16px;
      margin-left: 10px;
      margin-right: auto;
    }
  }

  .quo__timer{
    width: auto;
    justify-content: center;

    .countdown_group{
      width: auto;
    }
    .countdown_number{
      display: inline-block;
    }
    .countdown_unit{
      position: relative;
      top: 0;
      font-size: 100%;
      margin-right: 10px;
      color: inherit;
    }
  }

  .btn-share{
    color: $color-black;
    background: transparent;
    position: absolute;
    right: 10px;
    top: 10px;
    border-color: transparent;

    &:before{
      background-color: $color-black;
    }
    &:hover{
      color: $color-white;
    }
  }
}

.quo-searching__profile__wrapper{
  border-top: 1px $color-light-gray solid;

  .quo-searching__profile__list{
    width: 100%;
    display: flex;
    gap: 20px;
    border-bottom: 1px $color-light-gray solid;
    padding-top: $elementMargin;
    padding-bottom: $elementMargin;
    justify-content: space-between;
  }

  .profile__avatar{
    width: 250px;
    img{
      border-radius: 100%;
    }
  }
  .profile__name{
    margin-right: 10px;
  }
  .profile__location{
    display: inline-block;
  }

  .btn{
    margin-bottom: 0;
  }

  @media screen and (max-width: 767px) {
    .quo-searching__profile__list{
      display: block;
    }
    .profile__avatar{
      width: 100px;
    }
    .btn-group{
      width: 100%;
    }
  }
}

.quo__comment_list{
  display: flex;

  &:last-child{
    border-bottom: 1px $color-medium-gray solid;
  }

  .quo__comment_top{
    display: flex;
    margin-bottom: 10px;
  }

  .profile__avatar{
    width: 60px;
    min-height: 60px;
    margin-right: $elementMargin;
    margin-bottom: 0;
    img{
      border-radius: 100%;
    }
  }
  .quo__comment{
    font-size: 16px;
    margin-bottom: $elementMargin;
  }
  .quo__date{
    font-size: 90%;
    color: $color-medium-gray;
    margin-left: 5px;
  }

  @media screen and (max-width: 768px) {
    .quo__comment_top{
      display: block;
    }
    .quo__date{
      margin-left: 0;
    }
  }
}

.quo__opencall__page__container{
  padding-top: 60px;
  margin-left: 15px;
  margin-right: 15px;

  .quo__badge{
    width: auto;
    margin-right: 0;
    margin-bottom: $elementMargin;
  }
  .quo__delivery{
    text-align: center;
  }
  .quo__timer{
    margin-left: auto;
    margin-right: auto;
  }
}

.quo__admin__container{
  padding-top: 60px;
  padding-bottom: 30px;
  .row{
    justify-content: center;
  }
  .form__form-group-label{
    display: none;
  }
  .form__button-toolbar{
    width: 100%;
    justify-content: center;
  }
}

.opencall__no-quo-message{
  height: 50px;
  position: absolute;
  top: calc( 50vh - 25px);
  left: 100px;
  right: 100px;
  text-align: center;
  p{
    font-weight: $font-bold;
  }
}