.wallet__container {

  &.container {
    padding-top: 30px;
    display: flex;
    gap: 30px;
    justify-content: center;

    @media screen and (max-width: 992px) {
      padding-top: $siteTopMobileMargin;
      display: block;
    }
  }


  .btn {
    margin-bottom: 0;

    &.btn-connect-stripe {
      color: $color-white;
      border-color: #5469d4;
      background-color: #5469d4;

      &::before {
        background-color: darken(#5469d4, 10%);
      }

      &:hover,
      &.before {
        border-color: darken(#5469d4, 10%);
        color: $color-white;
      }

      i {
        font-size: 18px;
        vertical-align: text-bottom;
        margin-left: 5px;
      }
    }
  }

  .btn-toolbar {
    margin-bottom: 0;
  }

  .faq__section {
    .collapse__wrapper {
      margin-left: auto;
      margin-right: auto;
    }
  }
}


.wallet__balance {
  min-width: 300px;
}

.quo__balance {
  margin-bottom: 20px;

  .card__title {
    text-transform: none;
    margin-bottom: 10px;
    font-weight: normal;
  }

  .quo__stats {
    justify-content: left;
  }

  .quo__stat {
    display: flex;
  }

  .quo_balance-title {
    color: $color-additional;
  }

  .quo_balance-stat {
    font-weight: $font-xbold;
  }

  .pending__box {
    background-color: $color-accent;
    padding: 15px;
    border-radius: 15px;

    .card__title {
      font-size: 14px;
      margin: 0;
    }

    .quo__stat {
      height: auto;
    }

    .quo_balance-stat {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 767px) {
    display: block;

    .pending__box {
      margin-left: 0;
    }
  }
}

.quo__timer {
  width: 85px;
  height: 25px;
  font-size: 11px;
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  text-align: center;

  .countdown_group {
    width: 30%;
    position: relative;
  }

  .countdown_number {
    display: block;
  }

  .countdown_unit {
    font-size: 75%;
    position: absolute;
    top: 12px;
    margin-left: 4px;
    color: $color-gray;
  }

  @media screen and (max-width: 767px) {
    font-size: 13px;
  }
}

.products-list__img-wrap {
  width: 100px;
}

.quo-submit_file {
  .table {
    font-size: 11px;
    overflow-wrap: anywhere;
  }
}


.no-wallet {
  margin-bottom: 30px;
  text-align: center;
  justify-content: center;

  .page-title {
    margin-bottom: 0;
  }
}

.no-wallet__steps {
  justify-content: center;

  .card__title {
    margin-bottom: 15px;
  }

  .subhead {
    color: $color-black;
  }
}



.unsettle__payment {
  text-align: center;
  justify-content: center;
  font-size: 18px;
  padding-top: 60px;
  padding-bottom: 30px;

  .bold-text {
    font-size: 150%;
  }

  .btn-connect-stripe {
    margin: 0 10px;
  }
}


.place__quo {
  padding-top: 30px;
  // min-height: 100vh;

  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }

  @media screen and (max-width: 768px) {
    padding-top: 60px;
  }

  .quo__checkout__form {
    margin-top: 40px;
  }

  .form.form--horizontal .form__button-toolbar {
    margin-top: 30px;
  }

  .row {
    justify-content: center;
    align-items: center;
  }
}

.wallet__activity {
  position: relative;

  .page-title {
    margin-top: 0;
  }

  .wallet-calendar {
    position: absolute;
    right: 0;
    top: 0;

    input {
      padding: 5px 10px;
      cursor: pointer;
    }
  }

  .table-responsive {
    thead th {
      background: transparent;
      border-bottom: 1px darken($color-white, 30%) solid;
    }

    tbody tr {
      border-bottom: 1px darken($color-white, 20%) solid;

      td{
        color: $color-dark-gray;
      }
    }

    .align-center{
      text-align: center;
    }

    @media screen and (max-width: 768px) {
      width: auto;
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}