.product-edit {

  .dropzone__img {
    min-width: 150px;
  }

  .product__preview{
    width: 100%;
    margin-bottom: 30px;
    background-color: $color-black;
    padding: 30px;

    .catalog-items{
      justify-content: center;
    }
    .catalog-item__body{
      margin: 0;
    }
    .card{
      padding-bottom: 0;
    }
  }
}