.theme-light {
  background-color: $color-light-gray;
}

ol {
  ol {
    list-style: lower-roman;
  }

}

.wrapper {
  max-width: 1630px;
  min-height: 100vh;
  margin: auto;
  box-shadow: 0 15px 30px $color-gray;
}

.quobal__logo {
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: left;
  background-size: 90%;

  @include themify($themes) {
    background-image: themed('logoImg');
  }

  @media screen and (min-width: 835px) {
    display: block;
  }

  &.horizontal {
    @include themify($themes) {
      width: 140px;
      background-image: themed('logoImgH');
      background-size: cover;
    }
  }
}

.icon-quo {
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  fill: $color-black;

  &.light-grey{
    fill: $color-light-gray;
  }
}

.icon-menu{
  display: inline-block;
  fill: $color-black;
}


.icon-google {
  width: 18px;
  height: 18px;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  background-size: cover;
  background-image: url(#{$frontendPath}/img/Google__G__logo.svg);
  margin: 0;
}


.icon-twitter {
  width: 18px;
  height: 18px;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  background-size: cover;
  background-image: url(#{$frontendPath}/img/twitter-logo.svg);
  margin: 0;

  &.white {
    background-image: url(#{$frontendPath}/img/icon-quo-white.svg);
  }

  &.light-grey {
    background-image: url(#{$assetPath}/561d0733-5d46-49d8-a963-1454042d3b33.svg);
  }
}

.quabal__container {
  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }

  min-height: 100vh;
  max-width: 100%;
  // padding-top: 50px;
  padding-bottom: 50px;

  .row {
    justify-content: center;
  }

  // @media screen and (max-width: 768px) {
  //   padding-top: $siteTopMobileMargin;
  // }
}

.terms__container {
  text-align: justify;
  padding-top: 50px;

  .page-title {
    font-size: 28px;
  }

  p {
    margin-bottom: 20px;
  }

  hr {
    margin: 30px 0;
  }

  ol,
  ul {
    ol {
      margin-top: 0;
    }

    li {
      margin-bottom: 10px;

      strong {
        margin-bottom: 10px;
        display: inline-block;
      }
    }
  }
}

.faq__container {
  padding-top: 30px;

  &.container {
    width: 70%;
    max-width: 800px;

    @media screen and (max-width: 768px) {
      padding-top: 60px;

    }
  }

  .collapse__wrapper {
    font-size: 14px;

    &.with-shadow {
      .collapse__title {
        padding: 10px 15px;
        padding-right: 30px;
        text-align: left;
      }

      .collapse__content {
        padding: 10px 15px;
      }

      svg {
        right: 10px;
        top: 5px;
      }
    }

    &.opened {
      background-color: $color-white;
    }
  }
}

.error__container {
  text-align: center;
  background-color: $color-accent !important;

  .page-title {
    font-size: 64px;
    line-height: 1.4;
    margin-top: 60px;
  }

  p {
    margin: 40px 0;
  }

  .quobal__logo {
    display: inline-block;
  }
}

.verification__container {
  padding-top: 60px;

  h3 {
    text-align: center;
  }

  .badge-icon {
    width: 100%;

    .icon {
      background-color: $color-green;
    }
  }

  .btn {
    margin-top: 30px;
  }

  .quobal__logo {
    margin: auto;
  }
}

.how-to-start__container {
  .page-title {
    text-align: center;
  }

  .steps__card__progress {
    width: 70%;
    margin: auto;
    text-align: center;

    h5 {
      margin-bottom: 15px;
    }
  }
}

.transaction__notice {
  padding: 10px;
  color: $color-gray;
}

.no-photo,
.badge-icon {
  width: 60%;
  margin: auto;
  text-align: center;
  padding-top: 30px;

  .btn-group{
    margin-top: 15px;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
  }

  .icon {
    background-color: $color-white;
    width: 140px;
    height: 140px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    margin: auto;
    margin-bottom: 30px;

    .las {
      margin: auto;
      font-size: 60px;
      color: $color-light-gray;
    }

  }

  .icon-quo {
    width: 50px;
    height: 50px;
    margin: auto;
    background-size: cover;
  }

  h5 {
    font-weight: $font-xbold;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
  }

  .username {
    text-transform: uppercase;
  }

  .btn-connect-stripe {
    margin-top: 30px;
  }
}

.no-media,
.no-quo,
.no-bookmark,
.no-wallet {
  padding-top: 30px;

  .page-title {
    text-align: center;
  }
}

.loading__container{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: $color-accent;

  .loading__animation{
    max-width: 250px;
  }
}

.blurr{
  filter: blur(4px);
}