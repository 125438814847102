.modal-dialog {
  margin-top: 50px;

  @media screen and (min-width: 768px) {
    max-width: 800px;
  }
}

.modal-content {
  border-radius: 15px;
  border: 4px solid $color-black;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  padding: 50px 40px;
  background-color: $color-white;

  h3.subhead {
    color: $color-black;
  }

  .btn-toolbar {
    gap: 15px;
    margin-bottom: 0;
  }
}

.nsfw--backdrop {
  opacity: 0.99;
}

.modal__close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 16px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: $color-black;
  z-index: 99;
}

.modal__title {
  margin-bottom: $elementXMargin;

  // .bold-text {
  //   margin-bottom: 1em;
  // }

  .bold-highlight {
    color: $color-blue;
  }
}

.submssion__form {
  .modal__quo-info {
    margin-bottom: 1em;
  }

  .modal__quo-name,
  .modal__quo-media {
    text-align: left;
  }

}

.share__profile__modal {
  .modal__title {
    text-align: center;
    font-size: 24px;
    font-weight: $font-xbold;
  }

  .modal__body {
    text-align: center;

    p {
      margin-bottom: $elementMargin;
      font-size: 16px;
    }
  }

  .profile__link {
    width: 60%;
    border: 1px $color-medium-gray solid;
    border-radius: 5px;
    padding: 10px 20px;
    margin: $elementMargin auto;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}

.quo__modal {

  &.fade {
    .modal-dialog {
      transform: translate(50px, 0);
    }
  }

  &.show {
    .modal-dialog {
      transform: none;
    }
  }

  .modal-dialog {
    max-width: 600px;
  }

  .card__title {
    margin-top: 0;
    text-transform: none;

    h5 {
      font-size: 1.4em;
      color: $color-white;
    }

    .quo__card__user {
      color: $color-accent;
      text-transform: uppercase;
    }
  }

  .form__form-group-label {
    color: $color-white;
  }

  .modal-content {
    background-color: $color-blue;
    background: linear-gradient(270deg, $color-accent 0, $color-blue 100%);
  }

  .form textarea {
    height: 100px;
  }

  .btn-toolbar {
    width: 100%;

    .btn {
      margin: auto;
    }

  }

  .icon-quo {
    width: 18px;
    height: 18px;
    overflow: hidden;
    background: url(#{$assetPath}/6adee41e-b113-4fb8-ac27-4b2583b3d00c.svg) center;
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin-right: 5px;
  }
}

.countering__modal {

  .modal-dialog {
    max-width: 600px;
  }

  .modal-content {
    background-color: $color-accent;
  }

  .modal__title {
    text-align: center;
  }
}

.open__detail__modal,
.quo__opencall__page__container {
  .quo__badge {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;

    .quo__badge-pic {
      width: 80px;
      height: 80px;
    }

    .quo__price {
      font-size: 24px;
    }

    .quo__detail {
      font-size: 20px;
    }
  }

  .page-title {
    margin-top: 0;
  }

  .btn-toolbar {
    margin-bottom: 30px;
  }

  .quo__timer {
    width: auto;
    justify-content: center;
    display: inline-block;

    .countdown_group {
      width: auto;
    }

    .countdown_number {
      display: inline-block;
    }

    .countdown_unit {
      position: relative;
      top: 0;
      font-size: 100%;
      margin-right: 10px;
      color: inherit;
    }
  }

  .quo__detail {
    margin-bottom: 20px;
    font-size: 16px;
  }

  .quo__delivery {
    text-align: center;
    margin-bottom: $elementMargin;
  }

  .quo__comment_list {
    border-top: 1px $color-medium-gray solid;
    // margin-top: $elementMargin;
    padding-top: $elementMargin;
    padding-bottom: $elementMargin;
  }
}

.share__image_modal {
  .modal__title {
    text-align: center;
    font-size: 24px;
    font-weight: $font-xbold;
  }

  .btn-toolbar {
    margin-top: 15px;
  }

  .copy_link {
    p {
      margin-bottom: 30px;
    }
  }
}

.lightbox_modal {
  max-width: inherit;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;

  .modal__close-btn {
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;

    .las {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }

  .modal-content {
    border: 0;
    height: 100vh;
    background-color: transparent;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .preview-container {
    width: 100%;
    max-width: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 100;

    img {
      max-width: 600px;
      max-height: calc(100vh - 140px);
    }
  }

  .video-container {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    video {
      max-width: 600px;
      max-height: calc(100vh - 140px);
    }
  }

  .modal__close-btn {
    color: $color-white;
  }

  .lightbox__footer {
    text-align: center;
    margin-top: $elementMargin;
    z-index: 100;

    .lightbox__download-btn {
      color: $color-white;

      &:hover {
        color: $color-accent;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .video-container {
      video {
        max-width: calc(100vw - 30px);
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}

.confirm__modal {
  .page-subhead {
    &.subhead {
      text-align: center;
      font-size: 18px;
      line-height: 1.5;
    }
  }

  .btn-toolbar {
    gap: 10px;

    .btn {
      margin: 0;
    }
  }
}

.lightbox_modal_container {
  text-align: center;
  z-index: 100;

  .lightbox_tool-container {
    margin-top: $elementMargin;
  }
}

.confirm__modal {
  max-width: 600px;
}

.warning__modal {
  text-align: center;

  .icon-eighteen {
    width: 60px;
    line-height: 60px;
    text-align: center;
    display: inline-block;
    background-color: $color-accent;
    border-radius: 100%;
    font-weight: $font-xbold;
    color: $color-red;
    font-size: 24px;
    margin-bottom: $elementMargin;
    font-style: normal;
  }

  .modal-dialog {
    max-width: 600px;
    margin-top: calc(50vh - 200px);
  }

  .btn{
    margin-bottom: 0;
  }
}

.warning__modal__backdrop {
  &.modal-backdrop.show {
    opacity: .9;
  }
}

.warning__modal__blur{
  filter: blur(4px);
}