.timeline {
  position: relative;
  margin-bottom: 30px;

  @include themify($themes) {
    color: themed('colorText');
  }

  &:before {
    content: "";
    background-color: $color-black;
    width: 2px;
    height: 100%;
    position: absolute;
    left: 50%;
  }

  p {
    margin-top: 5px;
  }
}

.timeline__item {
  position: relative;
  padding-bottom: 50px;
  text-align: left;

  .quo__status-name{
    margin-right: 2px;
  }

  &:nth-child(even) {

    .timeline__content {
      margin-left: calc(50% + 52px);
      text-align: left;

      .quo__status{
        justify-content: left;
      }


      &:after {
        right: auto;
        left: -20px;
        border: 10px solid transparent;

        @include themify($themes) {

          @include directify($directions) {
            #{directed('border-right')}: 10px solid themed('colorBackground');
          }
        }
      }
    }
  }
}

.timeline__icon {
  position: absolute;
  left: calc(50% - 25px);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 2px solid $color-black;
  background-color: $color-blue;
  overflow: hidden;
  text-align: center;
  display: flex;

  .las{
    margin: auto;
    font-size: 24px;
  }

  svg{
    margin: auto;
    width: 18px;
    fill: $color-white;
  }

  &.Countering{
    background-color: $color-accent;
  }

  &.Accepted,
  &.Delivered{
    background-color: $color-green;
    color: $color-white;
  }

  &.Rejected{
    background-color: $color-red;
    color: $color-white;
  }

  &.Timeout{
    background-color: $color-light-gray;
  }

  &.Completed,
  &.Rating{
    background-color: $color-white;
  }

  &.timeline__icon-yet{
    background-color: $color-light-gray;
  }

  img {
    height: 100%;
    width: auto;
    min-width: 100%;
  }

  span {
    color: white;
    font-size: 20px;
    margin: auto;
  }
}

.timeline__content {
  width: calc(50% - 52px);
  margin: 25px 0;
  position: relative;
  padding: 15px;
  border-radius: 15px;
  border: 2px solid $color-black;

  &.timeline__content-yet{
    background-color: $color-light-gray;
    .timeline__title{
      font-size: 12px;
      font-weight: normal;
      margin: 0;
    }
  }

  .quo__btn-delivered{
    width: 100%;
  }

  .quo__status{
    justify-content: left;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
  }

  .quo__btn{
    margin: 0 5px;
  }

  &:after {
    content: '';
    position: absolute;
    right: -20px;
    top: 20px;
    border: 10px solid transparent;

    @include themify($themes) {
      @include directify($directions) {
        #{directed('border-left')}: 10px solid themed('colorBackground');
      }
    }
  }
}

.timeline__title {
  font-weight: $font-xbold;
  font-size: 16px;
  margin-bottom: 10px;
  margin-right: 10px;
  display: inline-block;
}

h4.timeline__date{
  margin-bottom: 10px;
  font-size: 11px;
  display: inline-block;
}

@media screen and (max-width: 991px) {

  .timeline:before {
    left: 25px;
  }

  .timeline__icon {
    left: 0;
  }

  .timeline__item:nth-child(even) .timeline__content,
  .timeline__item .timeline__content {

    margin-left: 72px;
    width: calc(100% - 72px);
    text-align: left;

    &:after {
      right: auto;
      left: -20px;
      border: 10px solid transparent;
    }
  }
}