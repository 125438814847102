.rpb-root {
  display: flex;
  align-items: baseline;
}

.rpb-root ul{
  display: flex;
  align-items: baseline;
  list-style:none;
}
.rpb-root ul li[data-current=true] button,
.rpb-root ul li[data-current=false] button {
  background: transparent;
  border: none;
  color: $color-additional;
  height: 28px;
  font-size: 10px;
  padding: 5px;
  text-align: center;
  min-width: 28px;
  transition: all 0.3s;

  @include directify($directions) {
    #{directed('margin-right')}: 2px;
  }

  &:hover {
    color: $color-blue;
    background: transparent;
  }

  &:focus, &:active {
    box-shadow: none;
  }

  svg {
    transition: 0.3s;
  }


}

.rpb-root ul li button{

  &[aria-label=Start],
  &[aria-label=Prev],
  &[aria-label=Next],
  &[aria-label=End] {
    border-radius: 50% !important;
    width: 28px;
    height: 28px;
    background: transparent;
    border: none;
    color: $color-additional;
    height: 28px;
    font-size: 10px;
    padding: 5px;
    text-align: center;
    min-width: 28px;
    transition: all 0.3s;
  
    @include directify($directions) {
      #{directed('margin-right')}: 2px;
    }
  
    &:hover {
      background: transparent;
    }
  
    &:focus, &:active {
      box-shadow: none;
    }

    @include themify($themes) {
      background: themed('colorFieldsBorder');
    }

    &:hover {
      background: $color-blue;
      color: $color-white;

      svg {
        fill: white;
      }
    }

    &.rpb-item--disabled {
      cursor: default;
      opacity: 0.4;

      @include themify($themes) {
        background: themed('colorFieldsBorder');
      }

      svg {
        fill: $color-additional;
      }
    }
  }

  &.rpb-item--active {
    background-color: transparent;
    font-weight: bold;

    @include themify($themes) {
      color: themed('colorText');
    }
  }

  &.rpb-item--disable {
      @include themify($themes) {
        fill: themed('colorIcon');
      }

  }

  
}

.pagination__link-icon {
  width: 13px;
  height: 13px;
  fill: $color-additional;
}

.pagination__wrap {
  display: flex;
  margin-top: 15px;

  &.pagination__wrap--small {
    margin-top: 0;
    display: block;
  }
}

.pagination-info, .pagination__select-form {
  color: $color-additional;
  font-size: 12px;
  margin-top: auto;
  margin-bottom: auto;

  @include directify($directions) {
    #{directed('margin-right')}: 0px;
    #{directed('margin-left')}: 10px;
  }

  select {

    @include themify($themes) {
      background-color: themed('colorBackground');
    }
  }
}

.pagination__item-option {
  color: $color-additional;
  margin-bottom: 20px;
  font-size: 14px;

  @include directify($directions) {
    #{directed('margin-right')}: 0px;
    #{directed('margin-left')}: 10px;
  }
}