.chat__container {

  &.container {
    padding: 0;
    @media screen and (max-width: 992px) {
      padding-top: $siteTopMobileMargin;
    }
  }

  .card {
    padding-bottom: 0;
    border-radius: 0;
  }

  .card-body {
    padding: 0;
    box-shadow: none;
    background-color: transparent;
  }


}

.chat__emoji-panel {
  display: none;
  opacity: 0;

  &.open {
    display: flex;
    opacity: 1;
  }

  .emoji-mart {
    left: 50px;
    bottom: 50px !important;
    z-index: 10;
  }
}

.chat__dialog {

  .lazy-load-image-loaded,
  .chat__bubble-video-thumbnail,
  .chat__bubble-video-image {
    width: 150px;
    height: 150px;
    overflow: hidden;
  }

  .chat__bubble-image {
    padding-bottom: 100%;
    background: #EEE;
    width: 150px;
    height: 0;
    position: relative;
    display: block;

    img {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
    }
  }

  .quo__btn {
    &.quo__btn-submit {
      width: 50%;
    }
  }
}

.chat__bubble {
  .card-body {
    background-color: $color-white;
  }

  .chat__bubble-video-button {
    border: 0;
    padding: 0;
  }
}

.chat {
  height: 100vh;
  min-height: 450px;
  display: flex;
  position: relative;
  overflow: hidden;

  @include themify($themes) {
    .tabs {

      .nav-tabs {
        background-color: $color-black;
        border-color: $color-black;
        padding: 0;
        margin-top: 0;

        @media screen and (max-width: 768px) {
          margin-left: 60px;
        }

        .nav-item {
          margin-bottom: 0;
        }

        .nav-link {
          color: $color-gray;
          height: 54px;
          line-height: 21px;

          &:hover {
            color: $color-gray;
            background-color: transparent;
            border-color: $color-black;
          }

          &:focus,
          &.active {
            color: $color-white;
            border-color: $color-black;
            //border: 0;

            &:hover {
              color: $color-white;
              border-color: $color-black;
            }
          }
        }
      }
    }
  }
}

.chat__contact-list {
  width: 335px;
  height: 100%;
  overflow: hidden;
  position: relative;

  .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      width: 8px;
      height: 8px;
      background-color: $color-red;
      border-radius: 100%;
      text-align: center;
      margin-left: 3px;
      display: flex;
      color: $color-white;
      align-items: center;
      justify-content: center;
      font-style: normal;
      position: relative;

      &:before {
        background-color: rgba(229, 145, 162, 0.2);
        content: "";
        position: absolute;
        top: 50%;
        border-radius: 50%;
        // animation: beforePulse 1.5s infinite;
        left: 50%;
        // transform: translate(-50%, -50%);
      }

      span {
        display: none;
      }
    }

    .tabs__unread {
      display: none;

      &.exist {
        display: flex;
      }
    }
  }

  .tab-content {
    background-color: $color-blue;
  }
}

.chat__contacts {

  button {
    width: 100%;
    padding: 0;
    border: none;
    background: transparent;
    text-align: left;
  }
}

.chat__contact {
  height: 72px;
  display: flex;
  cursor: pointer;
  transition: all 0.3s;

  p {
    color: lighten($color-white, 50%);
  }

  @include themify($themes) {
    border-bottom: 1px solid themed('colorFieldsBorder');
  }

  &:hover {

    @include themify($themes) {
      background-color: themed('colorHover');
    }

    p {
      color: $color-black;
    }

  }

  &.chat__contact--active {

    @include themify($themes) {
      background-color: themed('colorHover');
    }

    p {
      color: $color-black;
    }

    &:hover {

      @include themify($themes) {
        background-color: themed('colorHover');
      }

      p {
        color: $color-black;
      }
    }
  }
}

.chat__contact-avatar {
  display: flex;
  flex-flow: column;
  align-self: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 40px;
  margin-right: 10px;
  margin-left: 15px;
  background-color: #fff;

}

.chat__contact-preview {
  display: flex;
  flex-flow: column;
  align-self: center;
  width: calc(100% - 80px);
  margin-right: 20px;
  margin-left: 0;
}

.chat__contact-name {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  color: $color-accent;
}

.chat__contact-post {
  margin-top: 0;
  margin-bottom: 3px;
  font-size: 10px;
  line-height: 1.2;
  color: $color-additional;
}

.chat__contact-last-message {
  margin: 4px 0 0 0;
  font-size: 12px;
  line-height: 1.33;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: lighten($color-white, 10%);
}

.chat__dialog-container {
  width: 100%;
  background-color: $color-white;
  padding-bottom: 60px;
  border-left: 4px solid #000000;
}

.chat__dialog {
  height: calc(100% - 50px);
  background-color: $color-dark-gray;
  border-top: 4px solid #000000;
  border-bottom: 4px solid #000000;
  overflow: hidden;
  position: relative;
}

.chat__contacts-scroll {
  height: calc(100vh - 54px);
  overflow: auto;

  .scroll-content {

    &,
    &>div {
      height: 100%;
    }
  }

  .scrollbar-track {

    &.scrollbar-track-y {
      width: 2px;
      margin-right: 3px;
    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
}

.chat__scroll {
  height: 100%;
  overflow-y: scroll;
  text-align: left;

  .scroll-content {

    &,
    &>div {
      height: 100%;
    }
  }

  .scrollbar-track {

    &.scrollbar-track-y {
      width: 2px;
      margin-right: 3px;
    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
}

.chat__dialog-messages-wrap {
  min-height: 100%;
  width: 100%;
  display: flex;
}

.chat__dialog-messages {
  padding: 20px;
  width: 100%;
}

.chat__bubble-message-wrap {
  max-width: 570px;
  border-radius: 0 40px 40px 20px;
  position: relative;
  padding: 20px;

  @include themify($themes) {
    background-color: themed('colorBubble');
  }

  @media screen and (min-width: 1366px) {
    position: static;
  }

  &.chat__bubble-message-wrap--file {
    padding: 20px;
  }

  img {
    max-width: 215px;
  }
}

.chat__bubble-download-wrap {
  max-width: 215px;
  margin-top: 10px;
  font-size: 10px;

  @media screen and (min-width: 1366px) {
    position: absolute;
    top: 0;
    max-width: calc(100% - 375px);
    width: 300px;
    margin-top: 0;
    left: 315px;
  }
}

.chat__bubble-file-name {
  white-space: nowrap;
  display: flex;

  span:first-child {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  span:last-child {
    color: $color-additional;
    margin-left: 5px;
  }
}

.chat__bubble-download {
  color: $color-accent;
  font-weight: 500;

  &:hover {
    color: $color-accent-hover;
  }
}

.chat__bubble-avatar {
  width: 40px;
  height: 40px;
  min-width: 40px;
  overflow: hidden;
  border-radius: 50%;
  display: none;
  margin-right: 10px;

  img {
    height: 100%;
    width: 100%;
  }

}

.chat__bubble-contact-name {
  margin-top: 0;
  margin-bottom: 2px;
  font-size: 13px;
  font-weight: 500;
  color: $color-accent;
  padding-right: 50px;
}

.chat__bubble-message {
  margin: 0 0 10px 0;
  font-size: 12px;

  @include themify($themes) {
    color: themed('colorText');
  }
}

.chat__bubble-date {
  color: $color-additional;
  font-size: 9px;
  margin-top: 10px;
  margin-right: 10px;
  text-align: end;
  right: 36px;

  @media screen and (min-width: 1366px) {
    right: 0px;
  }
}

.chat__bubble {
  display: flex;
  margin-bottom: 20px;
  position: relative;

  .quo__badge {
    .quo__badge-pic {
      width: 35px;
      height: 35px;
    }
  }

  &.chat__bubble--active {

    .chat__bubble-message-wrap {
      border-radius: 40px 0 20px 40px;
      margin-left: auto;
    }

  }

  &:last-child {
    margin-bottom: 0;
  }

  .chat__bubble-checkmark {
    margin-left: 5px;

    .la-check-double {
      color: $color-blue;
    }
  }
}

.chat__bubble__quo {

  .chat__bubble-message-wrap {
    background-color: transparent;
  }

  .chat__quo-action {
    font-size: 14px;
    text-align: right;
    color: $color-gray;
    display: flex;
    align-items: center;
    position: absolute;
    right: 15px;
    top: 15px;

    .bold-text {
      color: inherit !important;
      margin-left: 5px;
      font-weight: $font-bold;
    }

    .las {
      font-size: 20px;
    }
  }

  .chat__bubble-date {
    position: absolute;
    bottom: 20px;
    right: 15px;
  }

  .bold-text {
    text-align: center;
    margin: 5px 0;
  }

  .chat__bubble-message-wrap {
    padding: 0;
  }

  .btn-toolbar {
    justify-content: center;
  }

  .quo__body {
    min-width: 250px;
    border: 4px solid $color-black;
    padding: 20px;
    border-radius: 0 40px 40px 20px;
  }

  &.chat__bubble--active {
    .quo__body {
      border-radius: 40px 0 20px 40px;
    }
  }

  .quo__badge {
    margin: auto;
    width: auto;
  }

  .quo__info {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .chat__bubble-date {
    color: $color-black;
  }

  .quo__body-countering,
  .quo__body-rating,
  .quo__body-completed {
    .chat__bubble-date {
      color: $color-black !important;
    }
  }

  .quo__body.quo__body-pending,
  .quo__body.quo__body-rejected,
  .quo__body.quo__body-accepted,
  .quo__body.quo__body-delivered {
    .chat__quo-action {
      color: $color-white;
    }

    h5 {
      color: $color-white;
    }
  }
}

.chat__dialog-select-message,
.chat__dialog-messages-empty {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;

  p {
    margin: auto;
    border-radius: 15px;
    padding: 5px 20px;
    color: $color-additional;

    @include themify($themes) {
      background-color: themed('colorBackgroundBody');
    }
  }
}

.chat__search {
  width: 100%;
  height: 50px;
  display: flex;
  position: relative;
  margin-right: 30px;
  align-items: center;


  svg {
    fill: $color-white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 18px;
    width: 18px;
    pointer-events: none;
    left: 15px;
  }
}

.chat__search-input {
  width: 90%;
  height: 80%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 38px;
  padding-right: 10px;
  border: 1px solid #eee;
  color: $color-white;
  margin: auto;

  &:focus,
  &:active {
    outline: none;
    border-color: $color-blue;
  }

  &::-webkit-input-placeholder {
    color: $color-white;
  }

  &::-moz-placeholder {
    color: $color-white;
  }

  &:-moz-placeholder {
    color: $color-white;
  }

  &:-ms-input-placeholder {
    color: $color-white;
  }
}

.chat__text-field {
  width: 100%;
  position: relative;
}

.chat__form {
  width: 100%;
  height: 60px;
  position: relative;
  padding: 6px 0;

  @media screen and (max-width: 768px) {

    .chat__field-controls-left,
    .chat__field-controls {
      font-size: 20px;
    }
  }
}

.chat__field-input {
  width: calc(100% - 130px);
  height: 36px;
  border-radius: 0;
  border-bottom: 1px solid $color-black;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  font-size: 12px;
  line-height: 1.33;
  transition: 0.3s;
  margin-left: 80px;

  @include themify($themes) {
    background-color: transparent;
    color: themed('colorText');
  }

  &:focus,
  &:hover,
  &:active {
    border-color: $color-blue;
    outline: none;
    box-shadow: none;
  }

  @media screen and (max-width: 768px) {
    width: calc(100% - 160px);
    margin-left: 100px;
  }

}

.chat__text-button {
  width: 100px;
  height: 38px;
  padding-top: 8px;
  margin-bottom: auto;
}

.chat__field-controls {
  position: absolute;
  top: 12px;
  height: 36px;
  display: flex;
  right: 20px;
}

.chat__field-controls-left {
  position: absolute;
  top: 12px;
  left: 20px;
  height: 36px;
  display: flex;
}

.chat__icon-button {
  background-color: transparent;
  color: $color-black;
  border: none;
  height: 36px;
  cursor: pointer;
  padding: 0 5px;
  margin-left: 5px;

  svg {
    height: 16px;
    width: 16px;
    fill: $color-additional;
    transition: 0.3s;
  }

  &:hover {

    svg {
      fill: $color-accent;
    }
  }
}

.chat__icon-attach {
  display: flex;
  align-items: center;
}

.chat__topbar {
  min-height: 50px;
  display: flex;
  align-items: center;
}

.chat__topbar-avatar {
  height: 100%;
  align-items: center;
  display: flex;

  img {
    height: 30px;
    width: 30px;
    border-radius: 100%;
    margin-right: 10px;
  }
}

.chat__topbar-button {
  background-color: transparent;
  border: none;
  transition: all 0.3s;
  cursor: pointer;
  width: 50px;
  height: 50px;
  font-size: 16px;

  &.chat__topbar-button--menu {
    margin-right: 0;
    display: none;
  }

  &:hover,
  .bookmarked {
    color: $color-blue;
  }

  @media screen and (min-width: 1024px) {
    &.chat__topbar-button--menu {
      display: none;
    }
  }
}

.chat_topbar-quo {
  width: auto;
  height: 34px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: $color-accent;
  border-radius: 50px;
  margin-right: 10px;
  font-weight: $font-bold;
  color: $color-black;

  .icon-quo {
    width: 18px;
    height: 18px;
    overflow: hidden;
    background: url(#{$assetPath}/6adee41e-b113-4fb8-ac27-4b2583b3d00c.svg) center;
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: bottom;
    margin-right: 5px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 0;
    margin-right: 5px;

    .icon-quo {
      margin: 0;
    }

    .text {
      display: none;
    }
  }

}

.chat__topbar-button-icon {
  height: 18px;
  width: 18px;
  transition: 0.3s;

  @include themify($themes) {
    fill: themed('colorIcon');
  }
}

.chat__topbar-contact {
  height: 50px;
  display: flex;
  padding-left: 15px;
  align-items: center;
  text-align: left;
}

p.chat__topbar-contact-name {
  font-size: 18px;
  line-height: 1;
  font-weight: $font-xbold;
  display: flex;
  margin: 0;
  cursor: default;

  a {
    color: $color-black;

    &:hover {
      color: $color-blue;
    }
  }
}

.chat__topbar-contact-post {
  margin: 0;
  color: $color-white;
  font-size: 20px;
  line-height: 1.45;
  font-weight: $font-xbold;
  display: flex;
}

.chat__topbar-right {
  margin-left: auto;
  display: flex;
}

.chat__topbar-controls {
  display: flex;
}

.chat__contact-unread {

  .chat__contact-name,
  .chat__contact-last-message {
    font-weight: $font-xbold;
  }

  .chat__contact-last-message {
    color: lighten($color-white, 0%);
  }

  i {
    position: absolute;
    right: 10px;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background-color: $color-red;
    color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;

    span {
      font-size: 9px;
    }
  }
}

@media screen and (max-width: 768px) {
  .chat__topbar-button.chat__topbar-button--menu {
    display: block;
  }

  .chat__dialog-container {
    margin-right: -100%;
    border: 0;
    z-index: 1;

    &.open {
      margin-left: -100%;
    }
  }

  .chat__contact-list {
    width: 100%;
  }

  .chat {

    &.chat--open {

      &:before {
        opacity: 0.2;
        z-index: 1;
        transition: opacity 0.3s, z-index 0s;
        pointer-events: none;
      }

      .chat__scroll,
      .chat__text-field {
        pointer-events: none;
      }
    }

  }
}

.chat__empty-conversation-message {
  color: $color-white;
  text-align: center;
  margin-top: 100px;
}

.chat__bubble-quo {
  min-height: 250px;
  position: relative;

  .chat__bubble-checkmark {
    .la-check-double {
      color: $color-black;
    }
  }
}

.chat__contacts-empty-box {
  text-align: center;
  margin-top: 100px;
  font-size: 12px;
}

@media screen and (max-width: 992px) {
  .chat_wrap {
    .container {
      // padding: 0;
    }
  }

  .chat {
    border-radius: 0;
    height: auto;
    min-height: 320px;
    border: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    overflow: hidden;
    padding-top: 55px;

    .chat__dialog-container {
      border-top: 4px solid $color-black;
    }

    .nav-link {
      height: 50px;
    }
  }

  .chat__contacts-scroll {
    height: calc(100vh - 55px - 54px);
  }
}

@media screen and (max-width: 768px) {
  .layout--chat {
    display: none;
  }

  .chat {
    padding-top: 0;
  }

  .chat__contacts-scroll {
    height: calc(100vh - 54px);
  }
}