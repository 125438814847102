main {
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Spartan', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.6;
}

html, body{
  height: 100%;
}

* {
  box-sizing: inherit;
}

ul, ol {
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  li{
    margin-bottom: 5px;
  }
}

a {
  color: $color-blue;
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
    color: $color-blue-hover;
  }
}

img {
  width: 100%;
}

button, input, optgroup, select, textarea{
  font-family: 'Spartan', sans-serif;
}

.center{
  margin: auto;
}

.align-center{
  text-align: center;
}

/* input slider styling */

input[type=range] {
  height: 25px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: $color-light-gray;
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid $color-blue-hover;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: $color-blue;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: $color-light-gray;
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid $color-blue-hover;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: $color-blue;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: $color-light-gray;
  border: 0px solid #000000;
  border-radius: 2px;
}
input[type=range]::-ms-fill-upper {
  background: $color-light-gray;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid $color-blue-hover;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: $color-blue;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: $color-light-gray;
}
input[type=range]:focus::-ms-fill-upper {
  background: $color-light-gray;
}

/* input slider styling */