.landing {
  max-width: inherit;

  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }

  .subhead {
    font-size: 30px;
    line-height: 1.6;
    font-weight: $font-xbold;
    // letter-spacing: -1px;
    // -webkit-text-stroke: 2px $color-black;
    // text-shadow: -3px 3px #000000;
    // text-shadow:
    //    3px 3px 0 #000,
    //    -2px -1px 0 #000,  
    //    1px -1px 0 #000,
    //    -1px 1px 0 #000,
    //    1px 1px 0 #000;
    // -webkit-text-fill-color: $color-white;
    color: $color-black;
    margin-bottom: 15px;
  }

  .bold-header {
    font-weight: $font-bold;
    font-size: 20px;
    margin-bottom: 20px;
    color: $color-black;
  }

  .description,
  .bold-text {
    margin: auto;
    font-size: 16px;
    line-height: 1.6;
    color: $color-black;
  }

  .btn-toolbar {
    .btn {
      font-size: 18px;
      margin-bottom: 0;
    }
  }


  @media screen and (max-width: 768px) {
    .landing-logo {
      width: 260px;
      height: 100px;
      margin-top: 30px;
    }

    .subhead {
      padding: 0 $siteMobileMargin;
      font-size: 28px;
    }

  }
}

.landing__feature {
  background-color: #DAEBEF;
  height: 92vh;
  max-height: 1280px;
  margin: 0;
  position: relative;
  text-align: center;

  .landing-logo {
    width: 260px;
    text-align: center;
    background: url(#{$frontendPath}/img/quobal-logo-only.svg) no-repeat center;
    background-size: cover;
    text-indent: 100%;
    overflow: hidden;
    margin: auto;
    left: calc(50% - 130px);
    z-index: 1;
  }

  .get_fixed {
    position: fixed;
    height: 260px;
  }

  .page-title {
    text-align: center;
    font-size: 38px;
    line-height: 1.6;
    font-weight: $font-xbold;
    color: $color-black;
    margin-bottom: 0;
    padding-top: 10%;
    margin-top: 0;
    margin-left: $siteMargin;
    margin-right: $siteMargin;

  }

  .bold-header {
    font-weight: $font-bold;
    color: $color-black;
    margin: 15px auto;
    text-align: center;
    font-size: 20px;
    display: inline-block;
  }

  .visual {
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  .landing__section {
    min-height: 300px;
    padding: 0;
    z-index: 1;
  }

  .btn-toolbar {
    .btn {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .page-title {
      font-size: 32px;
      margin-left: $siteMobileMargin;
      margin-right: $siteMobileMargin;
      padding-top: 100px;
    }

    .bold-header {
      font-size: 18px;
    }

    .description {
      max-width: 100%;
      margin-left: $siteMobileMargin;
      margin-right: $siteMobileMargin;
    }

    .landing-logo {
      width: 100%;
      height: 240px;
      left: 0;
      background-size: 70%;
      margin-top: 80px;
    }
  }
}

.landing__section {
  min-height: 400px;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;

  .subhead {
    .highlight {
      color: $color-blue;
      -webkit-text-fill-color: $color-blue;
      background-color: transparent;
    }

  }

  .btn-toolbar{
    margin-top: 30px;
  }
}


.landing__grey {
  background-color: $color-dark-gray;
  position: relative;
  z-index: 1;
  margin-left: -15px;
  margin-right: -15px;

  .spacer {
    height: 9vh;
  }
}

.landing__yellow {
  background-color: $color-yellow;
  position: relative;
  z-index: 1;
  margin-left: -15px;
  margin-right: -15px;
  padding-bottom: 60px;

  ::selection {
    background-color: $color-blue;
  }
}

.quobal__section {
  padding-top: 0;
  padding-bottom: 100px;

  .subhead {
    text-align: center;
    margin-bottom: 20px;
    text-shadow:
      3px 3px 0 #000,
      -2px -1px 0 #000,
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000;
    -webkit-text-fill-color: $color-white;
  }

  p {
    font-size: 16px;
    text-align: center;
    color: $color-white;
  }

  .landing-logo {
    width: 280px;
    height: 250px;
    background: url(#{$frontendPath}/img/quobal-logo-rgb.svg) no-repeat center;
    background-size: cover;
    text-indent: 100%;
    overflow: hidden;
    margin: auto;
    z-index: 1;
  }

  .btn-toolbar {
    margin-top: 50px;
    padding-bottom: 100px;
  }
}

.quobal__section_b {
  .bold-header {
    .bold-text {
      background-color: $color-blue;
      font-size: inherit;
    }
  }

  .creators-visual {
    width: 100%;
    height: 300px;
    background: url(#{$frontendPath}/img/testimonial-visual.svg) no-repeat center;
    background-size: contain;
    text-indent: 100%;
    overflow: hidden;
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 0px;
  }
}

.say_goodbye_section {
  padding-top: 0;
  align-items: normal;

  .icon {
    width: 20px;
    margin-left: 10px;
  }

  .subhead {
    padding: 0;
    text-shadow:
      3px 3px 0 #000,
      -2px -1px 0 #000,
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000;
    -webkit-text-fill-color: $color-white;
    text-align: center;
  }

  .say_goodbye_wrapper {
    text-align: center;
    position: absolute;
    height: 100%;

    .say_goodbye_content {
      position: sticky;
      top: 230px;
      margin: 0 20px;
    }
  }

  .quobal_benefits {
    .bold-text {
      margin-bottom: 10px;
    }

    .description {
      margin-bottom: 50px;

      &.clear {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .say_goodbye_wrapper {
      position: relative;
      margin-top: 50px;
      margin-bottom: 50px;

      .say_goodbye_content {
        position: relative;
        top: 0;
      }
    }
  }
}

.testimonial-box {
  margin: auto;
  position: relative;

  .user {
    width: 100%;
    position: absolute;
    bottom: -80px;
    left: 0;
  }

  .user-avatar {
    width: 80px;
    margin: auto;
    margin-bottom: 10px;
    text-align: center;

    img {
      border: 4px solid $color-black;
      border-radius: 100%;
    }
  }

  .user-name {
    text-align: center;
    margin: auto;
    font-weight: $font-bold;
  }
}

.landing__marquee__wrapper {
  margin-top: 60px;
  margin-bottom: -20px;
  z-index: 10;
}

.landing__marquee {
  position: relative;
  min-width: 100vw;
  height: 46px;
  transform: rotate(-2deg);
  box-shadow: -4px 4px 0 $color-black;
  border-top: 2px solid $color-black;
  border-bottom: 2px solid $color-black;
  background-color: $color-white;
  z-index: 10;

  .track {
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 14s linear infinite;
    -webkit-animation: marquee 14s linear infinite;
  }

  .content {
    list-style: none;
    margin: 0;

    li {
      display: inline-block;
      margin: 10px 20px;
      font-weight: $font-xbold;
    }
  }
}

.marquee__b {
  transform: rotate(4deg);
  margin-top: -20px;
  margin-bottom: 20px;
  margin-right: -10px;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-30%);
  }
}

.creators__section-wrapper {
  overflow: hidden;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -80px;
  padding-bottom: 20px;

  .container {
    max-width: inherit;
  }
}

.creators__section {
  position: relative;
  min-height: 400px;
  margin: 0 -25px;

  .subhead {
    padding: 0;
    text-shadow:
      3px 3px 0 #000,
      -2px -1px 0 #000,
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000;
    -webkit-text-fill-color: $color-white;
  }

  .visual {
    max-width: 520px;
    padding: 0 40px;
    padding-bottom: 20px;
  }

  .winning__box {
    max-width: 350px;
    position: absolute;
    bottom: 50px;
    right: 30px;

    .no__box {
      background-color: $color-white;
      border: 2px solid $color-black;
      box-shadow: -4px 4px 0 $color-black;
      font-weight: $font-xbold;
      font-size: 20px;
      display: inline-block;
      padding: 6px 15px;
      text-transform: uppercase;
      margin-bottom: 10px;
      vertical-align: middle;

      .las {
        color: $color-red;
        font-size: 120%;
        margin-right: 10px;
      }
    }

    .bold-text {
      font-size: 28px;
      font-weight: 900;
      letter-spacing: -1px;
      color: $color-white;
      -webkit-text-fill-color: $color-white;
      -webkit-text-stroke: 2px $color-black;
      text-shadow: -3px 3px #000000;
      text-align: center;
    }
  }

  @media screen and (max-width: 768px) {
    .subhead {
      margin-left: $siteMobileMargin;
      margin-right: $siteMobileMargin;
      font-size: 30px;
    }

    .description {
      margin-left: $siteMobileMargin;
      margin-right: $siteMobileMargin;
    }

    .visual {
      width: 60%;
      margin: auto;
    }
  }
}

.c1 {
  background-color: $color-blue;

  .subhead {
    .highlight {
      color: $color-accent;
      -webkit-text-fill-color: $color-accent;
    }
  }
}

.c2 {
  background-color: $color-accent;
}

.c3 {
  background-color: $color-green;

  .description {
    color: $color-white;
  }

  .subhead {
    .highlight {
      color: $color-accent;
      -webkit-text-fill-color: $color-accent;
    }
  }
}

.c4 {
  background-color: $color-dark-gray;

  .description {
    color: $color-white;
  }
}

.benefit__section {
  .benefits-list {
    list-style: none;
    position: relative;
    padding-left: 30px;

    li {
      font-size: 18px;
      line-height: 1.6;
      margin-bottom: 10px;

      &:before {
        content: "\f00c";
        font-family: 'Line Awesome Free';
        font-weight: 900;
        color: $color-green;
        position: absolute;
        left: 0;
      }
    }
  }

  .btn-toolbar {
    margin-top: $elementXMargin;
  }
}

.fans__section {
  margin: 0 $siteMobileMargin;
  padding-top: 100px;

  .subhead {
    text-align: center;
  }

  .tv_icon {
    display: flex;
    align-items: center;
    z-index: 10;

    span {
      width: 100%;
      position: absolute;
      bottom: -40px;
      left: 0;
      font-weight: $font-xbold;
      font-size: 18px;
    }
  }

  .as-creator {
    width: 100%;
    height: 120px;
    background: url(#{$frontendPath}/img/tvbox-creators.svg) no-repeat center;
    background-position-y: bottom;
    background-size: 100%;
    border: 4px solid $color-black;
    border-radius: 15px;
    position: relative;
    top: 15px;
    transition: all 0.3s;


    &:hover,
    &.active {
      background-color: $color-blue;
    }
  }

  .as-fans {
    width: 100%;
    height: 120px;
    background: url(#{$frontendPath}/img/tvbox-fans.svg) no-repeat center;
    background-position-y: bottom;
    background-size: 100%;
    border: 4px solid $color-black;
    border-radius: 15px;
    position: relative;
    top: 15px;
    transition: all 0.3s;

    &:hover,
    &.active {
      background-color: $color-accent;
    }
  }

  .deco-arrow {
    width: 100%;
    height: 24px;
    background-image: url(#{$assetPath}/6e3f7727-318a-4c61-b944-e21fc3dd9441.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 20px 0;
  }

  .tab-content {
    padding: 40px;
    position: relative;
  }

  .creator__benefits {
    .deco-arrow {
      background-image: url(#{$assetPath}/239b3b67-7630-4477-96a1-270b4f4a7330.svg);
    }
  }

  .benefits_list {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      line-height: 1.6;
      margin-bottom: 15px;
      text-align: center;
      font-size: 18px;
    }
  }

  .btn-toolbar {
    margin-top: $elementXMargin;
    margin-bottom: 50px;
  }


  @media screen and (max-width: 768px) {

    .as-creator,
    .as-fans {
      background-position: center 30px;
      background-size: contain;
      position: static;
      height: 130px;

      span {
        bottom: auto;
        top: 30px;
      }
    }

    .tab-content {
      min-height: 320px;
    }

    .deco-arrow {
      width: 30px;
      height: 250px;
      background-image: url(#{$assetPath}/658694be-1f54-4c39-81a0-e1d38f85d5da.svg);
      position: absolute;
      right: -30px;
      top: 0;
      bottom: auto;
    }

    .creator__benefits {
      .deco-arrow {
        background-image: url(#{$assetPath}/7ca918e1-0ffd-477b-a454-e042a131f9c7.svg);
        left: -30px;
        top: 0;
        right: auto;
      }
    }
  }
}

.manage_quo__section {
  padding: 0;
  align-items: normal;

  .mq_top {
    width: 100%;
    position: absolute;
    top: 60px;
    z-index: 2;
  }

  .mq_bottom {
    width: 100%;
    position: absolute;
    bottom: 30px;
  }

  .subhead {
    text-align: center;
    margin-bottom: 0;
  }

  .bold-header {
    text-align: center;

    .bold-text {
      background-color: $color-accent;
      font-size: inherit;
    }
  }

  .bold-text {
    text-align: center;
    max-width: 600px;
  }

  .description {
    text-align: center;
    font-size: 14px;
    max-width: 600px;
  }

  .steps-item {
    width: 100vw;
    margin-top: 60px;
    // max-height: 500px;
    // margin-bottom: 80px;
    // margin-left: 80px;
    // margin-right: 80px;
  }

  .visual {
    width: 350px;
    margin: auto;

    [role="button"] {
      cursor: default;
    }
  }

  .steps {
    text-align: center;
    margin-bottom: 10px;
    font-size: 16px;
    display: inline-block;
    margin-right: 10px;

    span {
      width: 30px;
      height: 30px;
      padding: 5px;
      background-color: $color-accent;
      display: inline-block;
      border-radius: 100%;
      font-weight: $font-xbold;
    }
  }

  .btn-toolbar {
    margin-top: 50px;
  }

  @media screen and (max-width: 768px) {

    .mq_top,
    .mq_bottom {
      position: relative;
      margin: 40px 0;
    }

    .steps-item {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}

.roundup__section {
  .visual {
    width: 220px;
    margin: auto;
    margin-bottom: 20px;
  }

  .description {
    text-align: center;
    margin: auto;
  }

  .btn-toolbar {
    margin-bottom: 60px;
  }

  .contact_us {
    text-align: center;
    font-size: 24px;
    font-weight: $font-xbold;
    margin-top: 30px;
  }

  @media screen and (max-width: 768px) {
    .description {
      width: 100%;
    }
  }
}

footer {
  .footer-links {
    list-style: none;
    padding-left: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 0;

    li {
      padding: 0 2px;
      font-size: 12px;
    }

    a {
      color: $color-gray;

      &:hover {
        color: $color-blue;
      }
    }
  }

  .copyright {
    text-align: center;
  }

}

.faq__section {
  font-size: 16px;

  .subhead {
    text-align: center;
  }

  .collapse__wrapper {
    background-color: $color-white;
    border-radius: 15px;
    box-shadow: -4px 4px 0 #000000;
    margin-bottom: 15px;
  }

  .collapse__title {
    font-size: 18px;
    border-radius: 15px;
  }

  .collapse__content {
    padding-top: 0;

  }

  .collapse__icon {
    font-size: 20px;
  }

  .btn-toolbar {
    margin-top: 50px;
  }
}

.landing__reg__form {
  max-width: 400px;
  margin: auto;
  position: relative;

  label {
    position: absolute;
    left: 15px;
    line-height: 58px;
    font-size: 1rem;
    font-weight: $font-bold;
    margin-bottom: 0;
  }

  .form-control {
    height: 58px;
    text-indent: 85px;
    border-radius: 50px;
    margin-bottom: 0;
  }

  .btn {
    position: absolute;
    right: 8px;
    top: 8px;
    margin-bottom: 0;
  }

  @media screen and (max-width: 768px) {
    width: auto;
  }
}

.landing__buying__selling {
  position: relative;

  .fix__content {
    width: 50vw;
    height: 100%;
    position: fixed;
    padding: 0 30px;
    overflow-y: auto;
    background-color: $color-accent;
    z-index: 102;
    left: 0;
    top: 40px;
  }

  &.landing {
    background-color: $color-white;
  }

  .landing-logo {
    width: 320px;
    height: 130px;
    background: url(#{$assetPath}/quobal-logo-only.svg) no-repeat center;
    text-align: center;
    background-size: contain;
    text-indent: 100%;
    overflow: hidden;
    margin: 15px auto;

    &.landing__share {
      background: url(#{$assetPath}/quobal-logo-horizontal-rgb.svg) no-repeat center;
      width: 400px;
      height: 160px;
      z-index: 100;
      position: absolute;
      top: 60px;
      left: calc(50% - 200px);

    }

    @media screen and (max-width: 992px) {
      height: 90px;
      margin-top: 10px;
      margin-bottom: 0;

      &.landing__share {
        top: calc(50vh - 160px);

      }
    }
  }

  .page-title {
    font-size: 22px;
    text-shadow:
      3px 3px 0 #000,
      -2px -1px 0 #000,
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000;
    -webkit-text-fill-color: $color-white;
    margin-bottom: 20px;
    text-align: center;

    &.landing__share {
      position: fixed;
      top: 240px;
      width: 100%;
      z-index: 100;

      @media screen and (max-width: 992px) {
        top: 50vh;
      }
    }
  }

  .subhead {
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
    padding: 0;

    &.landing__share {
      font-size: 42px;
      margin-top: 320px;

      @media screen and (max-width: 992px) {
        margin-top: 15vh;
      }
    }
  }

  .caption {
    font-size: 12px;
    color: $color-gray;
    text-align: center;
  }

  .benefits__section {
    display: flex;
    justify-content: center;
  }

  .benefits-list {
    list-style: none;
    position: relative;
    padding-left: 30px;

    li {
      font-size: 13px;
      line-height: 1.6;
      margin-bottom: 10px;

      strong {
        font-size: 120%;
      }

      &:before {
        content: "\f00c";
        font-family: 'Line Awesome Free';
        font-weight: 900;
        font-size: 120%;
        color: $color-green;
        position: absolute;
        left: 0;
      }
    }
  }

  .landing__section {
    margin-left: 54vw;
    margin-right: 2vw;
  }

  .landing__section__header {
    font-size: 15px;
    margin-top: 15px;
    text-align: center;
  }

  .landing__reg__form {
    margin-top: 10px;
    margin-bottom: 5px;

    .form-group {
      margin-bottom: 0;
    }
  }

  .copyright {
    color: $color-dark-gray;
  }

  footer {
    display: flex;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    font-size: 11px;

    .left {
      display: flex;
    }
  }

  .footer-links {
    margin-top: 0;
    margin-bottom: 10px;
    height: 70px;

    a {
      color: $color-dark-gray;

    }

    i {
      font-size: 16px;
    }

    li {
      font-size: inherit;
    }
  }

  .tab-page {
    width: 50vw;
    height: 40px;
    position: fixed;
    top: 0;
    // left: calc(50vw - 200px);
    left: 0;
    background-color: $color-light-gray;
    z-index: 101;
    border-radius: 5px 5px 0 0;
    overflow: hidden;

    :last-child {
      border-right: 0;
    }
  }

  .btn-page {
    width: 50%;
    height: 100%;
    font-weight: $font-xbold;
    font-size: 14px;
    line-height: 32px;
    vertical-align: middle;
    padding: 4px 10px;
    transition: all 0.3s;
    color: lighten($color-gray, 20%);
    text-align: center;
    display: inline-block;
    // border-right: 1px lighten($color-light-gray, 10%) solid;

    &:hover {
      background-color: $color-black;
      color: $color-white;
    }

    &.active {
      cursor: default;
    }

  }

  &.buyer {

    ::selection {
      background-color: $color-blue;
    }

    .btn-page {
      &.active {
        background-color: $color-accent;
        color: $color-black;
      }
    }
  }

  &.seller {

    ::selection {
      background-color: $color-accent;
    }

    .fix__content {
      background-color: $color-blue;
    }

    .btn-secondary {
      background-color: $color-accent;
      border-color: $color-accent;
      color: $color-black;
    }

    .caption {
      color: $color-dark-gray;
    }

    .copyright,
    .footer-links a {
      color: $color-dark-gray;
    }

    .benefits-list li:before {
      color: #0d2d22;
    }


    .btn-page {
      &.active {
        background-color: $color-blue;
        color: $color-black;
      }
    }
  }

  @media screen and (min-width: 1600px) {

    .tab-page,
    .fix__content {
      width: 810px;
      margin-left: -15px;
      left: auto;
    }

    .landing__section {
      margin-left: 830px;
      margin-right: 15px;
    }
  }

  @media screen and (max-width: 992px) {
    .fix__content {
      width: auto;
      position: relative;
      margin-left: -15px;
      margin-right: -15px;
      padding: 0 15px;
    }

    .landing__section {
      margin-left: 0;
      margin-right: 0;
    }

    .tab-page {
      left: 0;
      top: 0;
      height: 45px;
      border-radius: 5px;
    }

    footer {
      display: block;

      .footer-links {
        height: auto;
      }

      .left {
        justify-content: center;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .fix__content {
      overflow-y: hidden;
    }

    footer {
      .left {
        display: block;
      }
    }
  }
}