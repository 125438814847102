.media__library {
  .medialist {
    display: grid;

    .row {
      margin-left: -7px;
      margin-right: -7px;

      .col-6 {
        padding-right: 7px;
        padding-left: 7px;
      }
    }
  }

  .btn-toolbar {
    margin-top: 15px;

    .btn {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .form {
    display: block;
  }
}

.react-thumbnail-generator {
  height: 100%;
  background-color: $color-black;
  display: flex;
  align-items: center;

  img {
    object-fit: cover;
  }
}

.medialist__topbar {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    height: auto;
  }

  .medialist__topbar-right {
    align-items: center;
    display: flex;

    .btn-profile_update {
      .las {
        font-size: 16px;
        margin-right: 5px;
      }
    }

    .btn-primary {
      color: $color-white;
    }

    .btn-secondary {
      padding: 4px 15px;
      margin: 0;
      font-size: 12px;
    }

    .btn {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .divider {
    width: 1px;
    background-color: $color-light-gray;
    margin: 0 10px;
  }

  .form__form-group-label {
    margin-bottom: 0;
    margin-right: 10px;
    line-height: 30px;
    font-size: 12px;
  }

  .form__form-group-field {
    width: 200px;

    select {
      background-color: $color-white;
      margin-right: 10px;
    }
  }
}


.media__item__box {
  height: 95%;
  background-color: $color-white;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 30px;

  .preview-container{
    background-color: $color-light-gray;
    border-radius: 15px;
  }

  .control-btn {
    border-radius: 100%;
    width: 24px;
    height: 24px;

    &:before {
      background-color: $color-black;
    }

    /*&:hover, &:focus, &:active, &:active:focus {
      border-color: $color-black;
      background-color: $color-black;
      color: $color-white;
    }*/
  }

  .media__item__box-tool {
    margin-bottom: 15px;
    display: flex;

    .btn-ordering,
    .btn-delete {
      position: relative;
      cursor: pointer;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .btn-gallery-container,
    .btn-delete-container {
      display: flex;

      input[type=checkbox] {
        background-color: #CC0000;
        height: 0em;
        width: 0em;
        background: none;
      }
    }

    .btn-delete-container {
      color: $color-red;

      .checked {
        display: none;

        .las {
          margin: auto;
        }
      }

      .display-delete {
        .la-trash {
          display: none;
        }

        .checked {
          width: 100%;
          height: 100%;
          border: $color-red 1px solid;
          display: block;
          border-radius: 100%;
          font-size: 12px;
          display: flex;
          align-items: center;
          text-align: center;

          &:hover {
            border-color: transparent;
          }
        }
      }
    }

    .media__item__box-tool-right {
      margin-left: auto;
      display: flex;

      .btn-ordering {

        .number {
          width: 100%;
          height: 100%;
          background-color: $color-blue;
          border-radius: 100%;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 2;
          color: $color-white;
          line-height: 24px;
          font-size: 11px;
          display: none;
        }

        &.display-order {
          .number {
            display: block;
          }

          &:hover {
            border-color: transparent;
          }
        }

        &.isDisabled {
          color: $color-light-gray;
          cursor: default;

          &:hover {
            background-color: transparent;
          }
        }

      }

      .btn-gallery {
        .ordering {
          width: 100%;
          height: 100%;
          background-color: $color-blue;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 2;
          color: $color-white;
          line-height: 30px;
          font-size: 12px;
          display: none;
        }

        .display-order {
          display: block !important;
        }
      }
    }
  }

  .gallery__img-wrap {
    width: 100%;
    overflow: hidden;
    margin: 0;

    img {
      width: 100%;
    }
  }

  .media__item__name {
    margin-bottom: 15px;
    margin-top: 15px;
    word-break: break-all;
  }

  .media__item__type {
    color: $color-gray;
    font-size: 12px;
    word-break: break-all;
  }

  .media__item__box-order {
    display: inline-block;
    width: 26px;
    height: 26px;
    background-color: $color-blue;
    border-radius: 100%;
    float: right;
    text-align: center;
    color: $color-white;

    span {
      position: relative;
      top: 4px;
    }
  }


  .profileContentImg {
    object-fit: cover;
  }

  .audio-container{
    display: flex;
    align-items: center;
  }

  .video-js,
  .profileContentImg {
    width: 100%;
    height: 220px;


    audio{
      width: 90%;
      display: block;
      margin: auto;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      height: 120px;
    }
  }
}

.update_gallery {
  .form {
    display: block;
  }

  .media__item__box {
    padding: 0;
    height: auto;
    position: relative;
  }

  .medialist {
    margin-bottom: 30px;
  }

  .media__item__box-tool {
    justify-content: end;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10;

    .control-btn {
      width: 18px;
      height: 18px;
      border-radius: 5px;
    }
  }
}

.no-media {
  .medialist__topbar {
    display: block;
    text-align: center;
  }

  .medialist__topbar-right {
    display: inline-block;
  }

  .btn {
    margin-top: 15px;
  }
}

.modal-content {
  .iPKMWM {
    position: relative;
    top: 0;

    h5 {
      font-size: 13px;
    }
    .MuiToolbar-root{
      display: none;
    }
  }

  .dXOmFv {
    overflow: visible !important;
  }

}

.MuiTableContainer-root {
  .products-list__img-wrap{
    background-color: $color-light-gray;
  }
}

.MuiTable-root {
  .snapshot-generator {
    width: 100%;
    height: auto;
  }

  // video {
  //   display: none;
  // }
}

.delivered__list {
  display: flex;
  align-items: flex-start;

  .preview-container{
    margin-bottom: 15px;
  }

  .gallery__img-wrap {
    width: 100%;
    background-color: $color-light-gray;

    .profileContentImg {
      object-fit: cover;
    }

    .video-js,
    .profileContentImg {
      height: 220px;

      @media screen and (max-width: 768px) {
        height: 120px;
      }
    }
  }

  .file_name {
    width: 100%;
    min-height: 80px;
    position: absolute;
    top: calc(50% - 40px);
    background-color: rgba(0, 0, 0, .6);
    color: $color-white;
    padding: 5px;
  }
}

.MuiIconButton-label {
  color: $color-blue !important;
}

.videojsT video {
  border-radius: 15px;
  object-fit: cover;
}

.videojsT {
  border-radius: 15px;
  width: 100%;
}

.video-js * {
  border-radius: 15px
}

.video-container {
  position: relative;
}
.video-container .thumbnail {
  width: 100%;
}

.videojs-thumbnail-cover {
  width: 100% !important;
  background: url(#{$frontendPath}/img/play.svg) no-repeat center !important;
  background-size: 100px 100px !important;
  left: 0;
  position: absolute;
  top: 0;

  /* Take full size */
  height: 100%;
  width: 100%;

  /* Center the content */
  align-items: center;
  display: flex;
  justify-content: center;

}

.video-origin * {
  border-radius: 15px
}

.video-tag {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-area {
  border-radius: 20px;
  border: 5px solid #4a8c45;
}

.video-title {
  border-radius: 19px;
  background-color: #4a8c45;
  letter-spacing: 1.5px;
  padding: .7% 0 .3% 0;
  text-align: center;
}
.landing-video-container{
  border-radius: 19px;
  background-color: #4a8c45;
}